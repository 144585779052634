import React , { useEffect } from 'react'
import axios from 'axios';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import {withCookies , Cookies } from 'react-cookie'
import {useDispatch} from 'react-redux';

export default withCookies(withRouter(function Oauth({location,history}) {

    const dispatch = useDispatch()
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const state = params.get("state");


    const stateJson = (JSON.parse(state));
    // console.log(stateJson);


    

    const callFunc = () => {

		const data = { 
            grant_type : "authorization_code",
            client_id : "217aef66389e1e1e4dd640f461544ef3", //217aef66389e1e1e4dd640f461544ef3",
            redirect_uri : process.env.NEXT_PUBLIC_KAKAO_REDIRECT_URI,
            code : code
        }

        axios({
            method: "POST",
            url: "https://kauth.kakao.com/oauth/token",
            data : qs.stringify(data),
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded;charset=utf-8',
            },                                
        }) 
            
            .then(res => {
                // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
                const { access_token , expires_in, refresh_token , refresh_token_expires_in } = res.data;
                // console.log(access_token , expires_in, refresh_token , refresh_token_expires_in)
                axios.post( `${process.env.REACT_APP_BACK_URL}/api/loginCookie`,
                     {
                         access_token : access_token,
                         expires_in : expires_in,                        
                         refresh_token : refresh_token,
                         refresh_token_expires_in : refresh_token_expires_in,
                     },
                     {
                         withCredentials: "true"
                     }

                )
                     .then(res => {
                        //  console.log("then:" , res);
                         if(res.data.message === "ok"){
                            // 로그인 상태를 변경하기 위해 store dispath
                            dispatch({type:"SET_LOGIN_STATE",loginState:true});                              
                             if(stateJson.backUrl){
                                // 이전페이지로 이동.
                                history.push(stateJson.backUrl);
                             }else{
                                history.push('/');
                             }
                        }

                    })
                    .catch(error => {
                        console.error("error",error);
                        history.push("/login");
                    })                          
            })
            .catch(error => {
                console.log("error2",error);
                history.push("/login");
            })
    }

    useEffect(()=>{
        callFunc();
    },[])

    return (
        <>  
        </>
    )
}))
