import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/core/styles";
import MyScheduleBox from "./MyScheduleBox";
import { copyObj } from "../common/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 65px)",
    backgroundColor: theme.palette.background.paper,
    padding: "15px",
    boxSizing: "border-box",
  },
  todoContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  todoToolbar: {
    flexGrow: 10,
  },
  tool1: {
    height: "30%",
    border: "1px solid black",
    boxSizing: "border-box",
  },
  divider: {
    flexGrow: 1,
  },
  todoTable: {
    flexGrow: 30,
  },
  yesterday: {
    height: "15%",
    border: "1px solid black",
    boxSizing: "border-box",
    marginBottom: "1%",
    position: "relative",
    "& .boxTitle": {
      position: "absolute",
      top: "-10px",
      left: "5px",
      background: "white",
      padding: "0px 15px",
    },
  },
  today: {
    height: "36%",
    border: "1px solid black",
    boxSizing: "border-box",
  },
  tomorrow1: {
    height: "15%",
    border: "1px solid black",
    boxSizing: "border-box",
    marginTop: "1%",
  },
  tomorrow2: {
    height: "15%",
    border: "1px solid black",
    boxSizing: "border-box",
    marginTop: "1%",
  },
  tomorrow3: {
    height: "15%",
    border: "1px solid black",
    boxSizing: "border-box",
    marginTop: "1%",
  },
}));

export default function Todo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const dropRef = useRef([]);

  const dropYesterDayRef = useRef("");
  const dropTodayRef = useRef("");
  const dropTomorrow1Ref = useRef("");
  const dropTomorrow2Ref = useRef("");
  const dropTomorrow3Ref = useRef("");
  const testRef = useRef("");

  const [yesterDayData, setYesterDayData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [tomorrowData1, setTomorrowData1] = useState([]);
  const [tomorrowData2, setTomorrowData2] = useState([]);
  const [tomorrowData3, setTomorrowData3] = useState([]);

  dispatch({ type: "NAV_SELECTED", selectedIndex: 4 }); // 없는페이지로!
  dispatch({ type: "TOP_TITLE", title: "일정관리" });

  const getGA = () => {
    const pathName = window.location.pathname;
    ReactGA.initialize("UA-173628111-2");
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
  };

  const addData = (dataGubun, addObj) => {
    let newData;

    switch (dataGubun) {
      case "yesterday":
        newData = [...yesterDayData, addObj];
        setYesterDayData(newData);
        break;
      case "today":
        newData = [...todayData, addObj];
        setTodayData(newData);
        break;
      case "tomorrow1":
        newData = [...tomorrowData1, addObj];
        setTomorrowData1(newData);
        break;
    }
  };

  const removeData = (dataGubun, id) => {
    let newData;
    let delIndex;
    let returnObject;

    console.log("dataGubun", dataGubun);
    console.log("id", id);
    switch (dataGubun) {
      case "yesterday":
        newData = [...yesterDayData];
        delIndex = newData.findIndex((item) => item.id == id);
        console.log("delIndex", delIndex);
        returnObject = copyObj(newData[delIndex]);
        console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setYesterDayData(newData);
        break;
      case "today":
        newData = [...todayData];
        delIndex = newData.findIndex((item) => item.id == id);
        console.log("delIndex", delIndex);
        returnObject = copyObj(newData[delIndex]);
        console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setTodayData(newData);
        break;
      case "tomorrow1":
        newData = [...tomorrowData1];
        delIndex = newData.findIndex((item) => item.id == id);
        returnObject = copyObj(newData[delIndex]);
        console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setTomorrowData1(newData);

        break;
    }

    // console.log("returnObject", returnObject);
    return returnObject;
  };

  const setDropZone = (x, y) => {
    dropTodayRef.current.style.borderWidth = "1px";
    dropTodayRef.current.style.borderStyle = "solid";
    dropYesterDayRef.current.style.borderWidth = "1px";
    dropYesterDayRef.current.style.borderStyle = "solid";

    // dropTomorrow1Ref.current.style.borderWidth = "1px";
    // dropTomorrow1Ref.current.style.borderStyle = "solid";
    // dropTomorrow2Ref.current.style.borderWidth = "1px";
    // dropTomorrow2Ref.current.style.borderStyle = "solid";
    // dropTomorrow3Ref.current.style.borderWidth = "1px";
    // dropTomorrow3Ref.current.style.borderStyle = "solid";

    if (
      dropTodayRef.current.getBoundingClientRect().x <= x &&
      dropTodayRef.current.getBoundingClientRect().x + dropTodayRef.current.getBoundingClientRect().width >= x &&
      dropTodayRef.current.getBoundingClientRect().y <= y &&
      dropTodayRef.current.getBoundingClientRect().y + dropTodayRef.current.getBoundingClientRect().height >= y
    ) {
      dropTodayRef.current.style.borderWidth = "2px";
      dropTodayRef.current.current.style.borderStyle = "dashed";
    }

    if (
      dropYesterDayRef.current.getBoundingClientRect().x <= x &&
      dropYesterDayRef.current.getBoundingClientRect().x + dropYesterDayRef.current.getBoundingClientRect().width >= x &&
      dropYesterDayRef.current.getBoundingClientRect().y <= y &&
      dropYesterDayRef.current.getBoundingClientRect().y + dropYesterDayRef.current.getBoundingClientRect().height >= y
    ) {
      dropYesterDayRef.current.style.borderWidth = "2px";
      dropYesterDayRef.current.current.style.borderStyle = "dashed";
    }

    if (
      dropTomorrow1Ref.current.getBoundingClientRect().x <= x &&
      dropTomorrow1Ref.current.getBoundingClientRect().x + dropTomorrow1Ref.current.getBoundingClientRect().width >= x &&
      dropTomorrow1Ref.current.getBoundingClientRect().y <= y &&
      dropTomorrow1Ref.current.getBoundingClientRect().y + dropTomorrow1Ref.current.getBoundingClientRect().height >= y
    ) {
      dropTomorrow1Ref.current.style.borderWidth = "2px";
      dropTomorrow1Ref.current.current.style.borderStyle = "dashed";
    }

    if (
      dropTomorrow2Ref.current.getBoundingClientRect().x <= x &&
      dropTomorrow2Ref.current.getBoundingClientRect().x + dropTomorrow2Ref.current.getBoundingClientRect().width >= x &&
      dropTomorrow2Ref.current.getBoundingClientRect().y <= y &&
      dropTomorrow2Ref.current.getBoundingClientRect().y + dropTomorrow2Ref.current.getBoundingClientRect().height >= y
    ) {
      dropTomorrow2Ref.current.style.borderWidth = "2px";
      dropTomorrow2Ref.current.current.style.borderStyle = "dashed";
    }

    if (
      dropTomorrow3Ref.current.getBoundingClientRect().x <= x &&
      dropTomorrow3Ref.current.getBoundingClientRect().x + dropTomorrow3Ref.current.getBoundingClientRect().width >= x &&
      dropTomorrow3Ref.current.getBoundingClientRect().y <= y &&
      dropTomorrow3Ref.current.getBoundingClientRect().y + dropTomorrow3Ref.current.getBoundingClientRect().height >= y
    ) {
      dropTomorrow3Ref.current.style.borderWidth = "2px";
      dropTomorrow3Ref.current.current.style.borderStyle = "dashed";
    }
  };

  const setDropZoneEnd = (x, y) => {

    return;

    if (dropTodayRef) {
      dropTodayRef.current.style.borderWidth = "1px";
      dropTodayRef.current.style.borderStyle = "solid";
    }

    if (dropYesterDayRef) {
      dropYesterDayRef.current.style.borderWidth = "1px";
      dropYesterDayRef.current.style.borderStyle = "solid";
    }
    // dropTomorrow1Ref.current.style.borderWidth = "1px";
    // dropTomorrow1Ref.current.style.borderStyle = "solid";
    // dropTomorrow2Ref.current.style.borderWidth = "1px";
    // dropTomorrow2Ref.current.style.borderStyle = "solid";
    // dropTomorrow3Ref.current.style.borderWidth = "1px";
    // dropTomorrow3Ref.current.style.borderStyle = "solid";

    if (
      dropTodayRef.current.getBoundingClientRect().x <= x &&
      dropTodayRef.current.getBoundingClientRect().x + dropTodayRef.current.getBoundingClientRect().width >= x &&
      dropTodayRef.current.getBoundingClientRect().y <= y &&
      dropTodayRef.current.getBoundingClientRect().y + dropTodayRef.current.getBoundingClientRect().height >= y
    ) {
      return "today";
    }

    if (
      dropYesterDayRef.current.getBoundingClientRect().x <= x &&
      dropYesterDayRef.current.getBoundingClientRect().x + dropYesterDayRef.current.getBoundingClientRect().width >= x &&
      dropYesterDayRef.current.getBoundingClientRect().y <= y &&
      dropYesterDayRef.current.getBoundingClientRect().y + dropYesterDayRef.current.getBoundingClientRect().height >= y
    ) {
      return "yesterday";
    }

    if (
      dropTomorrow1Ref.current.getBoundingClientRect().x <= x &&
      dropTomorrow1Ref.current.getBoundingClientRect().x + dropTomorrow1Ref.current.getBoundingClientRect().width >= x &&
      dropTomorrow1Ref.current.getBoundingClientRect().y <= y &&
      dropTomorrow1Ref.current.getBoundingClientRect().y + dropTomorrow1Ref.current.getBoundingClientRect().height >= y
    ) {
      return "tomorrow1";
    }

    if (
      dropTomorrow2Ref.current.getBoundingClientRect().x <= x &&
      dropTomorrow2Ref.current.getBoundingClientRect().x + dropTomorrow2Ref.current.getBoundingClientRect().width >= x &&
      dropTomorrow2Ref.current.getBoundingClientRect().y <= y &&
      dropTomorrow2Ref.current.getBoundingClientRect().y + dropTomorrow2Ref.current.getBoundingClientRect().height >= y
    ) {
      return "tomorrow2";
    }

    if (
      dropTomorrow3Ref.current.getBoundingClientRect().x <= x &&
      dropTomorrow3Ref.current.getBoundingClientRect().x + dropTomorrow3Ref.current.getBoundingClientRect().width >= x &&
      dropTomorrow3Ref.current.getBoundingClientRect().y <= y &&
      dropTomorrow3Ref.current.getBoundingClientRect().y + dropTomorrow3Ref.current.getBoundingClientRect().height >= y
    ) {
      return "tomorrow3";
    }
  };

  useEffect(() => {
    getGA();
  }, []);

  useEffect(() => {
    setYesterDayData([
      {
        id: 5,
        content: "리엑트 더욱더 공부하기",
        state: "O",
      },
      {
        id: 6,
        content: "리엑트 더욱더 공부하기",
        state: "O",
      },
    ]);
    setTodayData([
      {
        id: 1,
        content: "라온이와 놀아주기",
        state: "O",
      },
      {
        id: 2,
        content: "REACT 공부하기",
        state: "O",
      },
      {
        id: 3,
        content: "몽고디비 마스터 하기",
        state: "O",
      },
      {
        id: 4,
        content: "캘리 그리기",
        state: "X",
      },
    ]);
    // console.log(yesterDayData);
    // console.log(todayData);
  }, []);


  console.log(dropTodayRef);
  console.log(testRef);
  

  return (
    <div ref={testRef}>
      <div className={classes.root}>
        <div className={classes.todoContainer}>
          <div className={classes.todoToolbar}>
            <div className={classes.tool1}></div>
            <div className={classes.tool2}></div>
            <div className={classes.tool3}></div>
          </div>
          <div className={classes.divider}></div>
          <div className={classes.todoTable}>
            <MyScheduleBox
              ref={dropYesterDayRef}
              setDropZoneEnd={setDropZoneEnd}
              setDropZone={setDropZone}
              addData={addData}
              removeData={removeData}
              title="어제"
              dropGubun="yesterday"
              className={classes.yesterday}
              data={yesterDayData}
            />
            <MyScheduleBox
              ref={dropTodayRef}
              setDropZoneEnd={setDropZoneEnd}
              setDropZone={setDropZone}
              addData={addData}
              removeData={removeData}
              title="오늘"
              dropGubun="today"
              className={classes.today}
              data={todayData}
            />
            <div className={classes.tomorrow1}></div>
            <div className={classes.tomorrow2}></div>
            <div className={classes.tomorrow3}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
