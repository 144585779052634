import React , { useEffect } from 'react'
import { useDispatch , useSelector } from 'react-redux';
import axios from 'axios'

export default function SetKey({location,history}) {

    const params = new URLSearchParams(location.search);
    const iduuid = params.get("iduuid");
    const pushKey = params.get("pushkey");
    const { isLogin , user } = useSelector(state => state.user)
    const dispatch = useDispatch()

    const saveKey = ()=>{
        // console.log()
        if(iduuid && pushKey){
            if(isLogin){
                // dispatch({type:"SET_PUSHKEY",androidID:iduuid,pushKey:pushKey}); 
                axios.post(`${process.env.REACT_APP_BACK_URL}/api/push`,{
                    loginGubun : user.loginGubun,
                    loginID: user.id,
                    androidID : iduuid,
                    pushKey : pushKey,
                })
                    .then(res =>{
                        // console.log("result",res.data.result)
                        if(res.data.result == "1"){
                            console.log("result")
                        }else{
                            // 저장에 실패하였습니다. // 이전데이터 표시!?
                        }
                    })
                    .catch(console.error)

            }else{
                // dispatch({type:"SET_PUSHKEY",androidID:iduuid,pushKey:pushKey}); 
            }
        }
    }

    useEffect(() => {
        saveKey()
    }, [])

    return (
        <>
            
        </>
    )
}
