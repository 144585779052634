// 레이아웃 , HOVER
export const NAV_SELECTED = "NAV_SELECTED"
export const TOP_TITLE = "TOP_TITLE"

// 사용자정보
export const SET_LOGIN_STATE = "SET_LOGIN_STATE"
export const SET_USERINFO = "SET_USERINFO"
export const CLEAR_USERINFO = "CLEAR_USERINFO"

// 푸시설정
export const SET_PUSHONOFF = "SET_PUSHONOFF"
export const SET_PUSHTIME = "SET_PUSHTIME"
export const SET_PUSHKEY = "SET_PUSHKEY"


