import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { makeStyles, withStyles, Badge, Avatar, Typography, TextareaAutosize, Button, Icon } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Scrollbars } from "react-custom-scrollbars";
import dayjs from "dayjs";
import { Mention } from "react-mentions";
import axios from "axios";
import useSWR, { useSWRInfinite } from "swr";
import useSocket from "../hooks/useSocket";
import useWindowSize from "../hooks/useWindowSize";
import regexifyString from "regexify-string";

const OnlineAvatar = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // height:'100%',
    height: "calc(100vh - 120px)",
  },
  topBar: {
    flex: 1 /* flex: 1 1 0 */,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#a9bdce",
    padding: "10px",
    maxHeight: "40px",
  },
  topBarImage: {
    marginRight: "0px",
  },
  topBarText: {
    marginLeft: "10px",
    flexBasis: "1",
  },
  messageBodyScrollBar: {
    flex: 10 /* flex: 1 1 0 */,
    backgroundColor: "#b2c7d9",
  },
  messageBodyContainer: {
    height: "100%",
  },
  messageBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    minHeight: "100%",

    // overflow: "scroll",
  },
  messageLeft: {
    alignSelf: "flex-start",
    // margin:'5px 5px 5px 0px',
    display: "flex",
    alignItems: "center",
    padding: "10px 0 5px 10px",
    maxWidth: "55vw",
    wordBreak: "break-all",
    "& div:first-child": {
      backgroundColor: "#eeeeee",
      padding: "10px",
      borderRadius: "10px",
    },
    "& div:last-child": {
      marginLeft: "10px",
    },
  },
  messageRight: {
    alignSelf: "flex-end",
    // margin:'5px 5px 5px 0px',
    display: "flex",
    alignItems: "center",
    padding: "10px 10px 5px 0px",
    maxWidth: "55vw",
    wordBreak: "break-all",
    "& div:first-child": {
      backgroundColor: "#eeeeee",
      padding: "10px",
      borderRadius: "10px",
      order: 2,
    },
    "& div:last-child": {
      marginRight: "10px",
      order: 1,
    },
  },
  messageInputBox: {
    flex: 1 /* flex: 1 1 0 */,
    display: "flex",
    margin: "4px 2px",
  },
  textarea: {
    flex: 8,
    margin: "0 15px 5px 0",
    height: "100%",
    padding: "5px",
  },
  button: {
    flex: 2,
    marginRight: "5px",
    backgroundColor: "#ffeb33",
    color: "black",
    borderRadius: "10px",
    maxHeight: "40px",
  },
  alert: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "100%",
  },
}));

export default function Message({ history, match }) {
  const classes = useStyles();
  const [content, setContent] = useState("");
  const [roomID, setRoomID] = useState("");
  const [ismessageError, setIsmessageError] = useState(false);
  const [allChatData, setAllChatData] = useState({});

  const [socket] = useSocket("chat");
  const { isLogin, user } = useSelector((state) => state.user);

  const scrollbarRef = useRef();
  const rootRef = useRef();
  const textareaRef = useRef();
  const size = useWindowSize();

  const beforeScrollTop = useRef(0);
  const chatOldMoreDatEmpty = useRef(false);

  if (scrollbarRef.current) {
    // console.log("현재 스크롤 정보 - top ", scrollbarRef.current.viewScrollTop);
    // console.log("현재 스크롤 정보 - height ", scrollbarRef.current.view.scrollHeight);
  }

  // 과거 채팅 로딩!
  const onScroll = useCallback(
    (values) => {
      if (values.scrollTop === 0) {
        // console.log(chatOldMoreDatEmpty);
        if (chatOldMoreDatEmpty.current) return;

        // 기존 스크롤값 저장!
        // console.log("onScroll");
        // console.log("스크롤이벤트 초기 스크롤크기", scrollbarRef.current.view.scrollHeight);
        beforeScrollTop.current = scrollbarRef.current.view.scrollHeight;

        setSize((prevSize) => prevSize + 1).then(() => {
          // beforeScrollTop =
          // 데이터 가져오고
          // console.log("scroll" , "chatData" , chatData)
          // 스크롤 고정 작업이 진행되고
          // 데이터가 변해서 useEffect 실행되고
          // useEffect 안에서 allChatData 가변경되고
          // 화면 렌더링 되고!
          // console.log("then");
          // // 스크롤 위치 고정!
          // if (scrollbarRef.current) {
          //   console.log("스크롤 고정");
          //   console.log(scrollbarRef.current.getScrollHeight()); // 현재 컨테이너의 스크롤 높이
          //   console.log(values.scrollTop); // 현재 스크롤바의 위치
          //   console.log(values.scrollHeight);
          //   // scrollbarRef.current.scrollTop(scrollbarRef.current.getScrollHeight() - values.scrollHeight);
          // }
        });

        // chatRevalidate(); // key 에 사용되는 url변수가 변경되지 않음!!!

        // swr인피니티가 좋음!!
      }
    },
    [scrollbarRef]
  );

  const scrollToBottom = () => {
    // console.log("scrollToBottom");
    // 스크롤 내리기
    if (scrollbarRef.current && scrollbarRef.current.scrollToBottom && typeof scrollbarRef.current.scrollToBottom === "function") {
      setTimeout(() => {
        if (scrollbarRef.current && scrollbarRef.current.scrollToBottom && typeof scrollbarRef.current.scrollToBottom === "function") {
          scrollbarRef.current.scrollToBottom();
        }
      }, 100);
      // scrollbarRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  const aloginGubun = match.params.loginGubun;
  const aloginId = match.params.loginId;

  const fetcher = async (url) => {
    const resp = await axios.get(url,{
      withCredentials: true,
    });
    // console.log(resp.data);
    return resp.data;
  };

  const { data: myInfo, mutate } = useSWR(`${process.env.REACT_APP_BACK_URL}/api/profile/${aloginGubun}/${aloginId}`, fetcher , {
    refreshInterval : 30000
  });
  
  let chatUser = [
    {
      loginGubun: "",
      loginID: 0,
    },
    {
      loginGubun: "",
      loginID: 0,
    },
  ];
  if (isLogin) {
    chatUser = [
      {
        loginGubun: aloginGubun,
        loginID: aloginId * 1,
      },
      {
        loginGubun: user.loginGubun,
        loginID: user.id * 1,
      },
    ];
  } else {
    const navUrl = "/" + window.location.pathname.split("/").slice(1).join("/");
    history.push("/login", { footNav: navUrl });
  }

  const { data: chatData, mutate: ChatMutate, revalidate: chatRevalidate, setSize } = useSWRInfinite(
    (index) => {
      // console.log("useSWR call");
      return `${process.env.REACT_APP_BACK_URL}/api/chat/${chatUser[0].loginGubun}/${chatUser[0].loginID}/${chatUser[1].loginGubun}/${
        chatUser[1].loginID
      }/page/${index + 1}`;
    },
    fetcher,
    {
      dedupingInterval: 100,
      // refreshInterval : 100,
      refreshWhenHidden: true,
      refreshWhenOffline: true,
    }
  );

  const onKeyDown = (e) => {
    // console.log("onkeydown");
    if (e.key == "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const sendMessage = () => {
    // console.log("sendMessage");

    if (content) {
      axios
        .put(`${process.env.REACT_APP_BACK_URL}/api/chat/${aloginGubun}/${aloginId}`, {
          sendUser: user,
          sendMessage: content,
        })
        .then((res) => {
          if (socket && roomID) {
            socket.emit("message", {
              wuser: {
                loginGubun: user.loginGubun,
                loginID: user.id,
              },
              wDateTime: new Date(),
              message: content,
              roomName: roomID,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          chatRevalidate();
          setIsmessageError(true);
          setTimeout(() => {
            setIsmessageError(false);
          }, 1500);
        });

      // const newChatData = Object.assign({}, chatData);
      // const newChatData = {...chatData , rdata : ...chatData.rdata }
      // console.log(chatData);
      const newChatData = copyObj(allChatData);
      // console.log("newChatData", newChatData);
      // console.log("i" , (newChatData === chatData))
      // console.log("i" , (newChatData.rdata.content === chatData.rdata.content))

      if (newChatData && newChatData.rdata && newChatData.rdata.content) {
        newChatData.rdata.content.push({
          wuser: {
            loginGubun: user.loginGubun,
            loginID: user.id,
          },
          wDateTime: new Date(),
          message: content,
        });

        ChatMutate(newChatData, false); //
        // console.log(chatData);
      } else {
        console.log("잇자나1", newChatData);
        console.log("잇자나2", newChatData.rdata);
        if (newChatData && newChatData.rdata) {
          newChatData.rdata.content = [
            {
              wuser: {
                loginGubun: user.loginGubun,
                loginID: user.id,
              },
              wdatetime: new Date(),
              message: content,
            },
          ];
          ChatMutate(newChatData, false);
        } else {
          console.log("채팅 데이터 읽기 실패!");
        }
      }

      // console.log("sendMessage after");
      // console.log(chatData);

      setContent("");
      textareaRef.current.focus();
      scrollToBottom();
    }
  };

  const onChangeContent = (e) => {
    setContent(e.target.value);
  };

  const copyObj = (obj) => {
    var copy = {};
    if (Array.isArray(obj)) {
      copy = obj.slice().map((v) => {
        return copyObj(v);
      });
    } else if (typeof obj === "object" && obj !== null) {
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = copyObj(obj[attr]);
        }
      }
    } else {
      copy = obj;
    }
    return copy;
  };

  const setOnlineList = () => {
    // 기존 채팅 데이터에 상대방의 데이터 추가!
    // 내 데이터는 쓸때 추가했으니 전체 전송에서 또 받으면 추가 안함!!
    // 내가 채팅한것은 무조건 아래로 옮기는데
    // 스크롤바 어느정도 올라가있으면 아래로 안떨구고
  };

  const renderSuggestion = useCallback((suggestion, search, highlightedDisplay, index, focused) => {
    return <Button focus={focused}></Button>;
  }, []);

  // 사이즈 조정하기!
  // 키보드 생겨났거나 주소창 있을때..
  useEffect(() => {
    //alert(size);
    //alert(rootRef.current);
    setTimeout(() => {
      //alert(size);
      //alert(rootRef.current);
      if (size && rootRef.current) {
        rootRef.current.style.height = `${size.height - 120}px`;
      }
    }, 200);
  }, [size, rootRef]);

  // 방만들기
  useEffect(() => {
    if (isLogin) {
      axios
        .post(`${process.env.REACT_APP_BACK_URL}/api/chat/room/`, {
          user: chatUser,
        },{
          withCredentials : true
        })
        .then((res) => {
          setRoomID(res.data._id);
          // chatRevalidate(); // 이거 왜 함?
        })
        .catch((err) => {
          console.log("방생성에 실패하였습니다");

        });
    }
  }, []);

  // 첫 로딩시 스크롤바 제일 아래로!!!
  useEffect(() => {
    scrollToBottom();
  }, []);

  // data 배열 받아 올때 마다 alldata에 합치기
  useEffect(() => {
    // console.log("chatdata - useEffect");
    // 마지막 chatData 를 기본으로 하고
    // 앞으로 오는 chatData의 content 부분을 기본 content 에 없으면 앞으로 넣는다.
    //setAllChatData(chatData[0]);

    // 데이터는 최신 100개 , 최신 101 ~ 200 개 이런식으로 가져오기 때문에
    // 최신자료가 추가 되면 추가된 자료는 화면에 자동추가된것이고 chatlist
    // chatData[0] 과 chatData[1] 은 실시간으로 추가된 데이터가 있기 때문에 겹칠수가 있다
    // 그래서 chatData[1]에서 chatData[0]에 있는 중복된 자료를 지우고
    // 통째로 chatData[0] 위에 넣는다.
    // 예)
    // chatData[1]
    // 1,2,3,4,5,6,7,8,9,10

    // chatData[0]
    // 5,6,7,8,9,10,11,12

    // 최종데이터는
    // 1,2,3,4,5,6,7,8,9,11,12
    if (chatData) {
      let newChatData;
      let copyChatData;
      if (Array.isArray(chatData)) {
        newChatData = copyObj(chatData[0]);
        copyChatData = copyObj(chatData);
        // console.log("chatData", chatData);
        // console.log("chatData.length", chatData.length);
        copyChatData.forEach((data, idx, arrData) => {
          if (idx > 0) {
            // console.log("리버스 객체1" , data.rdata.content.reverse())
            // console.log("리버스 객체2" , data.rdata.content.reverse())
            // console.log(data.rdata.content);
            if (data.rdata.content.length < 100) {
              // console.log("chatOldMoreDatEmpty" , "set");
              chatOldMoreDatEmpty.current = true;
            }
            data.rdata.content.reverse().forEach((contentData, idx) => {
              // console.log("리버스 객체 forEach 내부 " , idx , contentData)
              if (
                !newChatData.rdata.content.some((beforeContentData) => {
                  return JSON.stringify(contentData) === JSON.stringify(beforeContentData);
                })
              ) {
                // 발견되지 않음
                // console.log("발견되지 않음",contentData);
                // console.log("비교하는 상위 데이터",contentData , "발견안됨",idx);
                newChatData.rdata.content.unshift(contentData);
              } else {
                // console.log("비교하는 상위 데이터",contentData , "발견됨",idx);
              }
            });
          }
        });
      } else {
        newChatData = copyObj(chatData);
      }

      // 두개를 합쳐야함!
      // 그리고 state에 저장해야함!

      setAllChatData(newChatData);
      // if (scrollbarRef.current) {
      //   console.log(scrollbarRef.current.getScrollHeight());
      // }
      // scrollToBottom();
    }
  }, [chatData, scrollbarRef]);

  useEffect(() => {
    if (scrollbarRef.current) {
      // console.log("뉴 현재 스크롤컨테이너 크기", scrollbarRef.current.getScrollHeight());
      // console.log("뉴 이전 스크롤컨테이너 크기", beforeScrollTop.current);
      scrollbarRef.current.scrollTop(scrollbarRef.current.getScrollHeight() - beforeScrollTop.current);
    }
  }, [allChatData]);

  // 소켓 연결!
  useEffect(() => {
    // console.log("socket useeffect!!")
    // console.log("socket" , socket)
    // console.log("roomID" , roomID)
    if (socket && roomID) {
      // console.log(`join to${roomID}`);
      socket.emit("join", roomID);
    }
  }, [socket, roomID]);

  // 메시지!
  useEffect(() => {
    socket.on("join", (data) => {
      // console.log(data);
    });

    socket.on("message", (data) => {
      // console.log(chatData);
      // console.log(data);
      // const newChatData = { ...chatData };
      // const newChatData = Object.assign({}, chatData);
      // console.log("chatData", chatData.rdata.content.length);
      const newChatData = copyObj(allChatData);
      // console.log("chatData", chatData.rdata.content.length);
      // console.log("newChatData", newChatData.rdata.content.length);
      // console.log(chatData === newChatData)
      if (newChatData && newChatData.rdata && newChatData.rdata.content) {
        newChatData.rdata.content.push({
          wuser: {
            loginGubun: data.wuser.loginGubun,
            loginID: data.wuser.loginID,
          },
          wdatetime: data.wDateTime,
          message: data.message,
        });
        // console.log("ChatMutate1")
        ChatMutate(newChatData, false); //
        // console.log(chatData);
      } else {
        newChatData.rdata.content = [
          {
            wuser: {
              loginGubun: data.wuser.loginGubun,
              loginID: data.wuser.loginID,
            },
            wdatetime: data.wDateTime,
            message: data.message,
          },
        ];
        // console.log("ChatMutate2")
        ChatMutate((data) => {
          return newChatData;
        }, false); //
      }
      scrollToBottom();
    });
    return () => {
      socket.off("join");
      socket.off("message");
    };
  }, [allChatData]);

  // 사라질때!!
  useEffect(() => {
    return () => {
      socket.emit("leave", roomID);
    };
  }, []);

  if (!myInfo) {
    return <>로딩중</>;
  }

  // console.log("render");
  return (
    <>
      <div className={classes.root} ref={rootRef}>
        <div className={classes.topBar}>
          {myInfo.isOnline ? (
            <OnlineAvatar
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar alt={myInfo.nickname} src={myInfo.profile_image_url} className={classes.topBarImage} />
            </OnlineAvatar>
          ) : (
            <Avatar alt={myInfo.nickname} src={myInfo.profile_image_url} className={classes.topBarImage} />
          )}

          <Typography variant="h6" component="h6" nowrap className={classes.topBarText}>
            {myInfo.nickname}
          </Typography>
        </div>
        <Scrollbars autoHide ref={scrollbarRef} onScrollFrame={onScroll} className={classes.messageBodyScrollBar}>
          <div className={classes.messageBodyContainer}>
            <div className={classes.messageBody}>
              {allChatData &&
                allChatData.rdata &&
                allChatData.rdata.content &&
                allChatData.rdata.content.map((message, idx) => {
                  return (
                    <div
                      className={
                        message.wuser.loginGubun === user.loginGubun && message.wuser.loginID === user.id * 1
                          ? classes.messageRight
                          : classes.messageLeft
                      }
                      key={idx}
                    >
                      <div>
                        {regexifyString({
                          input: message.message,
                          pattern: /\n|\s/g,
                          decorator(match, index) {
                            // console.log("reg" , match,index);
                            if (match.match(/\n/)) {
                              return <br />;
                            }
                            if (match.match(/\s/)) {
                              return <span style={{ visibility: "hidden" }}>A</span>;
                            }
                          },
                        })}
                      </div>
                      <div>{dayjs(message.wdatetime).format("h:mm A")}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Scrollbars>
        <div className={classes.messageInputBox}>
          <TextareaAutosize
            aria-label="메시지입력창"
            value={content}
            onChange={onChangeContent}
            rowsMax={6}
            rowsMin={3}
            placeholder="메시지를 입력하세요."
            className={classes.textarea}
            onKeyDown={onKeyDown}
            ref={textareaRef}
          >
            <Mention
              appendSpaceOnAdd
              trigger="@"
              data={[
                {
                  id: 1,
                  display: "1",
                },
                {
                  id: 2,
                  display: "2",
                },
                {
                  id: 3,
                  display: "3",
                },
              ]}
              renderSuggestion={renderSuggestion}
            ></Mention>
          </TextareaAutosize>
          <Button variant="contained" color="primary" className={classes.button} onClick={sendMessage}>
            보내기
          </Button>
        </div>
      </div>
      {ismessageError && (
        <div className={classes.alert}>
          <Alert severity="error" color="error">
            메시지 전송에 실패하였습니다.
          </Alert>
        </div>
      )}
    </>
  );
}
