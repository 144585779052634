import * as type from '../actoins/type';

const initialState = {
  isLogin: false,
  user: {
    loginGubun: "",
    id: "",
    nickname: "",
    profile_image_url: "",
    thumbnail_image_url: "",
  }
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LOGIN_STATE: // 로그인 상태 변경
      return {
        ...state, isLogin: action.loginState
      };
    case type.SET_USERINFO:
      return {
        ...state, user: action.user
      };
      case type.CLEAR_USERINFO:
        return {
          ...state, isLogin : false , user : {loginGubun : "",id:"",nickname:"",profile_iamge_url:"",thumbnail_image_url:""}
        };      
    default:
      return state
  }
};

export default user;