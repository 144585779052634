import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '../reducers';

const persistConfig = {
    key: 'root',
    storage
};
const enhancedReducer = persistReducer(persistConfig, reducers);

//export default createStore(enhancedReducer, applyMiddleware());
//export default createStore(enhancedReducer, applyMiddleware(logger));
export default createStore(enhancedReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
