import React, { useCallback } from "react";
import io from "socket.io-client";

const sockets = {};

const useSocket = (workspace) => {
  // 끊기
  const disconnect = useCallback(() => {
    if (workspace) {
      sockets[workspace].disconnect();
      delete sockets[workspace];
    }
  }, [workspace]);

  if (!workspace) {
    return [undefined, disconnect];
  }

  // 연결 , 범위설정을 해야 한다!
  // namespace , room
  if (!sockets[workspace]) {
    // console.log(`${process.env.REACT_APP_BACK_URL}/ns-${workspace}`);
    sockets[workspace] = io.connect(`${process.env.REACT_APP_BACK_URL_S}/ns-${workspace}`, {
      transports: ["websocket"],
      secure : true
    });
  }

  // //보내기
  // sockets[workspace].emit("hello", "world"); // evnet명 , data

  // //받기
  // sockets[workspace].on("message", (data) => {
  //   // event명 , data를 파라미터로 받는 콜백함수
  //   console.log(data);
  // });

  // //받기
  // sockets[workspace].on("message2", (data) => {
  //   // event명 , data를 파라미터로 받는 콜백함수
  //   console.log(data);
  // });

  return [sockets[workspace], disconnect];
};

export default useSocket;
