import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import * as Util from "../common/Utils";
import { Redirect, Route, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    minWidth: 200,
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    boxSizing: "border-box",
  },

  alert: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "100%",
  },
}));

export default withRouter(function ThankYouWrite({ history, match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const postid = match.params.postid;

  const navUrl = "/" + window.location.pathname.split("/")[1];

  const [popOpen, setPopOpen] = useState(false);
  const [content, setContent] = useState("");
  const [publicFlag, setPublicFlag] = useState("public");
  const [alertShow, setAlertShow] = useState(false);
  const { isLogin } = useSelector((state) => state.user); // user 의 state 를 가져오기!

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (postid) {
      axios
        .get(`${process.env.REACT_APP_BACK_URL}/api/posts/${postid}`)
        .then((res) => {
          if (res.data.author.loginGubun == user.loginGubun && res.data.author.id == user.id) {
            setContent(res.data.content);
            setPublicFlag(res.data.public);
            console.log(res.data.public);
          } else {
            alert("잘못된 호출입니다.");
            history.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleOpen = () => {
    setPopOpen(true);
  };
  const handleClose = () => {
    setPopOpen(false);
  };

  const handleRadioChange = (event) => {
    setPublicFlag(event.target.value);
  };

  const onClick = () => {
    // console.log(user);
    if (postid) {
      axios
        .put(`${process.env.REACT_APP_BACK_URL}/api/posts/${postid}`, {
          content: content,
          public: publicFlag,
          author: user,
          published_date: Util.getFormatDate(new Date()),
        })
        .then((res) => {
          setAlertShow(true);
          setContent("");
          setTimeout(() => {
            setAlertShow(false);
          }, 1500);
        })
        .catch(console.error);
    } else {
      axios
        .post(`${process.env.REACT_APP_BACK_URL}/api/posts`, {
          content: content,
          public: publicFlag,
          author: user,
          published_date: Util.getFormatDate(new Date()),
        })
        .then((res) => {
          setAlertShow(true);
          setContent("");
          setTimeout(() => {
            setAlertShow(false);
          }, 1500);
        })
        .catch(console.error);
    }
  };

  const onContentChange = (event) => {
    setContent(event.target.value);
  };

  // loginCheck.
  const loginCheck = async () => {
    let returnData = false;
    await axios.post(`${process.env.REACT_APP_BACK_URL}/api/loginCheck`, {}, { withCredentials: true }).then((res) => {
      // console.log(1)
      if (res.data.message === "ok") {
        // console.log(2)
        returnData = true;
      }
    });
    return returnData;
  };

  useEffect(async () => {
    //let isLoggin = await loginCheck(); // 서버 체크 해서 accesstoken 으로 체크!

    // console.log(44,isLoggin);
    if (!isLogin) {
      history.push("/login", { footNav: navUrl });
    } else {
      if (postid) {
        dispatch({ type: "NAV_SELECTED", selectedIndex: 1 });
        dispatch({ type: "TOP_TITLE", title: "일기 수정" });
      } else {
        dispatch({ type: "NAV_SELECTED", selectedIndex: 1 });
        dispatch({ type: "TOP_TITLE", title: "일기 쓰기" });
      }
    }
    // console.log(55,isLoggin);
  }, []);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
        <Grid item xs={12} container direction="row" justify="flex-end" spacing={0}>
          <Button
            variant="contained"
            onClick={handleOpen}
            style={{
              margin: "10px 5px",
            }}
          >
            어떤글을 적으면 좋을까?
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            style={{
              margin: "10px 5px",
            }}
          >
            {postid ? "수정" : "등록"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="일기 작성"
            multiline
            rows={6}
            rowsMax={10}
            placeholder="일기를 적어보세요! 무엇이든 좋습니다."
            variant="outlined"
            value={content}
            onChange={onContentChange}
          />
        </Grid>
        <Grid>
          <RadioGroup row aria-label="quiz" name="quiz" value={publicFlag} onChange={handleRadioChange} defaultValue="public">
            <FormControlLabel value="public" control={<Radio />} label="공개" />
            <FormControlLabel value="pravate" control={<Radio />} label="비공개" />
          </RadioGroup>
        </Grid>
      </Grid>

      <Modal open={popOpen} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div className={classes.paper}>
          <Typography variant="h6" component="h6">
            {" "}
            어떤글을 적으면 좋을까?
          </Typography>
          {/* <img
            src="/images/thankyou001.jpg"
            alt="감사일기"
            style={{
              width: "60%",
            }}
          ></img>           */}
          <br />
          <ul>
            <li>
              긍정적 감정을 활성화시켜서 행복감을 증진시키는 <b>[감사일기]</b>
            </li>
            <br />
            <li>
              매일매일 한걸음 더 <strong>[3줄일기]</strong>
            </li>
            <br />
            <li>
              무엇이든 좋습니다.<br /><br />
              기록하고 싶은글,<br /> 보여주고 싶은글,<br /> 감사일기,<br />3줄일기<br />무엇이든 일단 적으시면 됩니다.<br /><br />
              꾸준히 적으면 적은만큼 성장할것입니다.
            </li>            
          </ul>
          <br />
          <Typography variant="h6" component="h6">
            작성팁 보기
          </Typography>
          <div>
            <button>감사일기</button>
            <button>3줄일기</button>
          </div>
        </div>
      </Modal>
      {alertShow && (
        <div className={classes.alert}>
          <Alert severity="success" color="info">
            정상적으로 등록되었습니다.
          </Alert>
        </div>
      )}
    </>
  );
});
