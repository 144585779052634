import React from 'react'
import ThankYouList from '../components/ThankYouList'
import TopBar from "../components/TopBar"

export const Header = () => {
    return (
        <>
            <TopBar />
        </>
    )
}
