import React from 'react'


export default function Login({history,location}) {
    

    
    // console.log(2,process.env.REACT_APP_KAKAO_REDIRECT_URI)
    let beforeFootNav = ""
    if(location.state && location.state.footNav)
        beforeFootNav = location.state.footNav;
    // console.log(beforeFootNav);

    const loginTry = () =>{
        if (false == window.Kakao.isInitialized()) {
            window.Kakao.init("217aef66389e1e1e4dd640f461544ef3");
            window.Kakao.isInitialized();
        }
        window.Kakao.Auth.authorize({
            redirectUri: `${process.env.REACT_APP_FRONT_URL}/oauth`,
            state : JSON.stringify({backUrl : beforeFootNav}),
            scope : 'profile,friends,talk_message'
        });        
    }
    return (
        <div>
            <div className="LoginBox">
                <div className="info">
                    <h4>카카오 로그인을 통해 <br />서비스 이용하실 수 있습니다.</h4>
                </div>
                <div>
                <img onClick={loginTry}
                    src="https://k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg"
                    width="222"
                />
                </div>
            </div>
        </div>
    )
}
