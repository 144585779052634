import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/core/styles";
import { copyObj } from "../common/Utils";
// import Draggable from "react-draggable";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 70px)",
    // border:'1px solid black'
  },
  mydiv: {
    margin: "25px",
    padding: "20px",
    minHeight: "200px",
    height: "15%",
    border: "1px solid black",
    borderTopLeftRadius: "255px 15px",
    borderTopRightRadius: "15px 255px",
    borderBottomRightRadius: "225px 15px",
    borderBottomLeftRadius: "15px 255px",
    boxSizing: "border-box",
    marginBottom: "1%",
    position: "relative",
    "& .boxTitle": {
      position: "absolute",
      top: "-10px",
      left: "5px",
      background: "white",
      padding: "0px 15px",
    },
  },
  itemList: {
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    alignItems: "flex-start",
    // position: "relative",
    // border: "1px solid black",
  },
  itemTop: {
    // position: "absolute",
    display: "inline-block",
    marginBottom: "1px",
  },
  item: {
    border: "1px solid black",
    display: "flex",
    justifyContent: "space-around",
    marginRight: "10px",
    "& .itemTitle": {
      fontSize: "0.9rem",
      width: "110px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      padding: "0px 10px",
    },
    "& .itemState1": {
      width: "15px",
      textAlign: "center",
      backgroundColor: "#32df24",
    },
    "& .itemState2": {
      width: "15px",
      textAlign: "center",
      backgroundColor: "#ff0f24",
    },
  },
  itemTest: {
    height: "100%",
    border: "1px solid black",
  },
}));

export default function MyDivM(props) {
  const classes = useStyles();
  const [todayData, setTodayData] = useState([]);
  const [tomorrowData, setTomorrowData] = useState([]);
  const [itemStyle, setItemStyle] = useState({});
  const selItem = useRef({
    selIng: 0,
    selItemGubun: "",
    selItemID: 0,
    selItemOriginX: 0,
    selItemOriginY: 0,
    selItemConclusionX: 0,
    selItemConclusionY: 0,
    selItemMoveX: 0,
    selItemMoveY: 0,
  });

  const dropRef = useRef([React.createRef(), React.createRef()]);

  const onShowContent = () => {
    // console.log(1);
  };

  const onChangeState = () => {
    // console.log(2);
  };

  const removeData = (dataGubun, id) => {
    let newData;
    let delIndex;
    let returnObject;

    // console.log("dataGubun", dataGubun);
    // console.log("id", id);
    switch (dataGubun) {
      case "today":
        newData = [...todayData];
        delIndex = newData.findIndex((item) => item.id == id);
        // console.log("delIndex", delIndex);
        returnObject = copyObj(newData[delIndex]);
        // console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setTodayData(newData);
        break;
      case "tomorrow":
        newData = [...tomorrowData];
        delIndex = newData.findIndex((item) => item.id == id);
        returnObject = copyObj(newData[delIndex]);
        console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setTomorrowData(newData);

        break;
    }

    // console.log("returnObject", returnObject);
    return returnObject;
  };

  const addData = (dataGubun, addObj) => {
    let newData;

    switch (dataGubun) {
      case "today":
        newData = [...todayData, addObj];
        setTodayData(newData);
        break;
      case "tomorrow":
        newData = [...tomorrowData, addObj];
        setTomorrowData(newData);
        break;
    }
  };

  const touchStart = (e) => {
    let containerGubun;
    if (e.target.closest(".drop-target") && e.target.closest(".itemTop")) {
      containerGubun = e.target.closest(".drop-target").dataset.targetgubun;

      // console.log(e.target);
      // console.log(e.target.closest(".itemTop").getBoundingClientRect().x)

      if (
        itemStyle.obj &&
        itemStyle.obj[e.target.closest(".itemTop").dataset.id] &&
        itemStyle.obj[e.target.closest(".itemTop").dataset.id].originX != 0 &&
        itemStyle.obj[e.target.closest(".itemTop").dataset.id].originY != 0
      ) {
        console.log("1");
        selItem.current = {
          selIng: 1,
          selItemGubun: containerGubun,
          selItemID: e.target.closest(".itemTop").dataset.id,
          selItemOriginX: itemStyle.obj[e.target.closest(".itemTop").dataset.id].originX,
          selItemOriginY: itemStyle.obj[e.target.closest(".itemTop").dataset.id].originY,
          selItemConclusionX: 0,
          selItemConclusionY: 0,
        };
      } else {
        // console.log("2",e.target.closest(".itemTop").getBoundingClientRect().x);
        // console.log("22",e.target.closest(".itemTop").getBoundingClientRect().width / 2);
        selItem.current = {
          selIng: 1,
          selItemGubun: containerGubun,
          selItemID: e.target.closest(".itemTop").dataset.id,
          selItemOriginX:
            e.target.closest(".itemTop").getBoundingClientRect().x + e.target.closest(".itemTop").getBoundingClientRect().width / 2, //selItem.current.selItemConclusionX,
          selItemOriginY:
            e.target.closest(".itemTop").getBoundingClientRect().y + e.target.closest(".itemTop").getBoundingClientRect().height / 2, //selItem.current.selItemConclusionY,
          selItemConclusionX: 0,
          selItemConclusionY: 0,
        };
      }

      // console.log("start", selItem.current);
      // console.log("start", selItem.current.selIng);

      // console.log("selsourceGubun", selsourceGubun.current);
      // console.log("selItemID", selItemID.current);
      // 모바일에 touch에서는 dataTransfer 가 없다!!
      // e.dataTransfer.setData("sourceGubun", containerGubun); // dataTransfer.setData
      // e.dataTransfer.setData("itemID", e.target.id); // dataTransfer.setData
    }
  };

  const touchMove = (e) => {
    // 이벤트가 발생된 마우스 좌표와 움직인 마우스 좌표
    // console.log("e",e) // 눈에 보이는 화면의좌표
    // console.log("clientX_clientY",e.targetTouches[0].clientX,e.targetTouches[0].clientY) // 눈에 보이는 화면의좌표
    // console.log("pageX_pageY",selItem.current.selItemConclusionX,selItem.current.selItemConclusionY) // 스크롤계산한 좌표
    if (selItem.current.selIng == 1) {
      // if (itemStyle.obj && itemStyle.obj[selItem.current.selItemID]) {
      //   selItem.current.selItemBeforeMoveX = itemStyle.obj[selItem.current.selItemID].x;
      //   selItem.current.selItemBeforeMoveY = itemStyle.obj[selItem.current.selItemID].y;
      // } else {
      //   selItem.current.selItemBeforeMoveX = 0;
      //   selItem.current.selItemBeforeMoveY = 0;
      // }
      // console.log("selItemBeforeMoveY", selItem.current.selItemBeforeMoveY);
      selItem.current.selItemConclusionX = e.targetTouches[0].pageX;
      selItem.current.selItemConclusionY = e.targetTouches[0].pageY;
      selItem.current.selItemMoveX = selItem.current.selItemConclusionX - selItem.current.selItemOriginX;
      selItem.current.selItemMoveY = selItem.current.selItemConclusionY - selItem.current.selItemOriginY;

      // console.log("selItem.current",selItem.current.selItemMoveX);
      // console.log("selItem.current",selItem.current.selItemMoveY);

      let tempobj = {};
      let obj = {};
      obj[selItem.current.selItemID] = {
        originX: selItem.current.selItemOriginX,
        originY: selItem.current.selItemOriginY,
        x: selItem.current.selItemMoveX,
        y: selItem.current.selItemMoveY,
      };
      // console.log("obj", obj);
      const newItemStyle = { ...itemStyle };
      if (newItemStyle.obj) {
        obj = Object.assign(newItemStyle.obj, obj);
      } else {
        obj = Object.assign({}, obj);
      }
      // console.log(1, itemStyle);
      setItemStyle({ ...itemStyle, obj });
      console.log("move", selItem.current);
      document.querySelectorAll(".drop-target").forEach((selItem) => {
        // console.log("selItem",selItem);
        selItem.style.borderWidth = "1px";
        selItem.style.borderStyle = "solid";
      });

      // console.log(dropRef.current[0].current.getBoundingClientRect());
      // console.log(dropRef.current[1].current.getBoundingClientRect());

      // const target = document.elementFromPoint(selItem.current.selItemConclusionX, selItem.current.selItemConclusionY);
      // console.log(dropRef.current[0].current.getBoundingClientRect());
      // console.log(dropRef.current[1].current.getBoundingClientRect());

      const dtIndex = dropRef.current.findIndex(
        (item) =>
          item.current.getBoundingClientRect().x <= selItem.current.selItemConclusionX &&
          item.current.getBoundingClientRect().x + item.current.getBoundingClientRect().width >= selItem.current.selItemConclusionX &&
          item.current.getBoundingClientRect().y <= selItem.current.selItemConclusionY &&
          item.current.getBoundingClientRect().y + item.current.getBoundingClientRect().height >= selItem.current.selItemConclusionY
      );

      if (dtIndex >= 0 && dropRef.current[dtIndex]) {
        // console.log(dtIndex);
        dropRef.current[dtIndex].current.style.borderWidth = "2px";
        dropRef.current[dtIndex].current.style.borderStyle = "dashed";
      }

      // const dropTarget = dropRef.current[
      //   dropRef.current.findIndex(
      //     (item) =>
      //       item.current.getBoundingClientRect().x < selItem.current.selItemConclusionX &&
      //       item.current.getBoundingClientRect().x + item.current.getBoundingClientRect().width > selItem.current.selItemConclusionX &&
      //       item.current.getBoundingClientRect().y < selItem.current.selItemConclusionY &&
      //       item.current.getBoundingClientRect().y + item.current.getBoundingClientRect().height > selItem.current.selItemConclusionX
      //   )
      // ].current;
      // console.log("dropTarget",dropTarget)
      // dropTarget.style.borderWidth = "2px";
      // dropTarget.style.borderWidth = "dashed";

      // if (target) {
      //   if (target.closest(".drop-target")) {
      //     //console.log(1,292.2666931152344, 202.6666717529297,document.elementFromPoint(292.2666931152344 , 202.6666717529297).closest(".drop-target").dataset.targetgubun)
      //     //console.log(2,selItem.current.selItemConclusionX, selItem.current.selItemConclusionY,target.closest(".drop-target").dataset.targetgubun)
      //     // console.log(target.closest(".drop-target"));
      //     if (target.closest(".drop-target")) {
      //       target.closest(".drop-target").style.borderWidth = "2px";
      //       target.closest(".drop-target").style.borderStyle = "dashed";
      //     }
      //   }
      // }
    }
  };

  const touchEnd = (e) => {
    // console.log(e);

    // console.log(selItem.current.selItemConclusionX);
    // console.log(selItem.current.selItemConclusionY);
    selItem.current.selIng = 0;
    selItem.current.selItemMoveX = 0;
    selItem.current.selItemMoveY = 0;
    selItem.current.selItemOriginX = 0;
    selItem.current.selItemOriginY = 0;

    let tempobj = {};
    let obj = {};
    obj[selItem.current.selItemID] = {
      originX: selItem.current.selItemOriginX,
      originY: selItem.current.selItemOriginY,
      x: selItem.current.selItemMoveX,
      y: selItem.current.selItemMoveY,
    };
    // console.log("obj", obj);
    const newItemStyle = { ...itemStyle };
    if (newItemStyle.obj) {
      obj = Object.assign(newItemStyle.obj, obj);
    } else {
      obj = Object.assign({}, obj);
    }
    // console.log(1, itemStyle);
    setItemStyle({ ...itemStyle, obj });
    // console.log(e.target.closest(".drop-target").dataset.targetgubun);
    // console.log(e.changedTouches[0].target.closest(".drop-target").dataset.targetgubun);

    // const target = document.elementFromPoint(selItem.current.selItemConclusionX, selItem.current.selItemConclusionY);
    const dtIndex = dropRef.current.findIndex(
      (item) =>
        item.current.getBoundingClientRect().x <= selItem.current.selItemConclusionX &&
        item.current.getBoundingClientRect().x + item.current.getBoundingClientRect().width >= selItem.current.selItemConclusionX &&
        item.current.getBoundingClientRect().y <= selItem.current.selItemConclusionY &&
        item.current.getBoundingClientRect().y + item.current.getBoundingClientRect().height >= selItem.current.selItemConclusionY
    );
    if (dtIndex >= 0 && dropRef.current[dtIndex]) {
      const targetGubun = dropRef.current[dtIndex].current.dataset.targetgubun;
      dropRef.current[dtIndex].current.style.borderWidth = "1px";
      dropRef.current[dtIndex].current.style.borderStyle = "solid";

      if (targetGubun !== selItem.current.selItemGubun && selItem.current.selItemGubun && targetGubun && selItem.current.selItemID) {
        addData(targetGubun, removeData(selItem.current.selItemGubun, selItem.current.selItemID));
      }
    }
  };

  // const dg_dragStart = (e) => {
  //   e.preventDefault();
  //   console.log(e.target.closest(".drop-target").dataset.targetgubun);
  //   console.log(e.target.closest(".itemTop").dataset.id);
  //   // console.log("dg_dragStart",e);
  // };

  // const dg_handleDrag = (e) => {
  //   e.preventDefault();
  //   // console.log("dg_handleDrag", e);
  // };

  // const dg_handleStop = (e) => {
  //   e.preventDefault();
  //   console.log(e.target.closest(".drop-target").dataset.targetgubun);
  //   console.log(e.target.closest(".itemTop").dataset.id);
  // };

  const dragStart = (e) => {
    let containerGubun;
    // console.log(e.target.dataset.id);
    // console.log("dragStart e", e);
    if (e.target.closest(".drop-target")) {
      // console.log(".drop-target in")
      // console.log("e.target.closest(.drop-target).classList" , e.target.closest(".drop-target").classList)
      containerGubun = e.target.closest(".drop-target").dataset.targetgubun;
      // console.log("containerGubun", containerGubun);
      e.dataTransfer.setData("sourceGubun", containerGubun); // dataTransfer.setData
      e.dataTransfer.setData("itemID", e.target.dataset.id); // dataTransfer.setData
    }
  };

  const draging = (e) => {
    e.preventDefault();
    // console.log("draging");
    if (e.target.closest(".drop-target")) {
      e.target.closest(".drop-target").style.borderWidth = "2px";
      e.target.closest(".drop-target").style.borderStyle = "dashed";
    }
  };

  const dragEnd = (e) => {
    e.preventDefault(); // 기본적으로 서로 다른 요소는 drop 이 안되는데 drop 가능하게 할려고!!
    // console.log(e.target.closest(".drop-target"))
    if (e.target.closest(".drop-target")) {
      e.target.closest(".drop-target").style.borderWidth = "1px";
      e.target.closest(".drop-target").style.borderStyle = "solid";
    }
  };

  const drop = (e) => {
    e.preventDefault();

    const souceGubun = e.dataTransfer.getData("sourceGubun");
    const souceID = e.dataTransfer.getData("itemID");
    let targetGubun;

    // console.log("drop in");
    if (e.target.closest(".drag-container")) {
      // console.log("drag-container in");
      targetGubun = e.target.closest(".drop-target").dataset.targetgubun;
      if (e.target.closest(".drop-target")) {
        e.target.closest(".drop-target").style.borderWidth = "1px";
        e.target.closest(".drop-target").style.borderStyle = "solid";
      }

      // console.log("souceGubun", souceGubun);
      // console.log("souceID", souceID);
      // console.log("targetGubun", targetGubun);

      if (targetGubun !== souceGubun && souceGubun && targetGubun && souceID) {
        addData(targetGubun, removeData(souceGubun, souceID));
      }
    }
  };

  useEffect(() => {
    setTodayData([
      {
        id: 1,
        content: "라온이와 놀아주기",
        state: "O",
      },
      {
        id: 2,
        content: "REACT 공부하기",
        state: "O",
      },
      {
        id: 3,
        content: "몽고디비 마스터 하기",
        state: "O",
      },
      {
        id: 4,
        content: "캘리 그리기",
        state: "X",
      },
    ]);
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={`${classes.mydiv} drop-target `}
        data-targetgubun="today"
        ref={dropRef.current[0]}
        onDrop={drop}
        onDragOver={draging}
        onDragLeave={dragEnd}
      >
        <div className="boxTitle">{"오늘"}</div>
        <div className={`${classes.itemList} drag-container `}>
          {todayData.map((item) => {
            return (
              <div
                className={`${classes.itemTop} itemTop `}
                key={item.id}
                data-id={item.id}
                draggable="true"
                onTouchMove={touchMove}
                onTouchStart={touchStart}
                onTouchEnd={touchEnd}
                onDragStart={dragStart}
                style={
                  itemStyle.obj &&
                  itemStyle.obj[item.id] && {
                    transform: `translateX(${itemStyle.obj[item.id].x}px) translateY(${itemStyle.obj[item.id].y}px)`,
                  }
                }
              >
                <div className={classes.item}>
                  <div className="itemTitle" onClick={onShowContent}>
                    {item.content}
                  </div>
                  <div className={item.state === "O" ? "itemState2" : "itemState1"} onClick={onChangeState}>
                    {item.state}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`${classes.mydiv} drop-target `}
        data-targetgubun="tomorrow"
        ref={dropRef.current[1]}
        onDrop={drop}
        onDragOver={draging}
        onDragLeave={dragEnd}
      >
        <div className="boxTitle">{"내일"}</div>
        <div className={`${classes.itemList} drag-container `}>
          {tomorrowData.map((item) => {
            return (
              <div
                className={`${classes.itemTop} itemTop `}
                key={item.id}
                data-id={item.id}
                draggable="true"
                onTouchMove={touchMove}
                onTouchStart={touchStart}
                onTouchEnd={touchEnd}
                onDragStart={dragStart}
                style={
                  itemStyle.obj &&
                  itemStyle.obj[item.id] && {
                    transform: `translateX(${itemStyle.obj[item.id].x}px) translateY(${itemStyle.obj[item.id].y}px)`,
                  }
                }
              >
                <div className={classes.item}>
                  <div className="itemTitle" onClick={onShowContent}>
                    {item.content}
                  </div>
                  <div className={item.state === "O" ? "itemState2" : "itemState1"} onClick={onChangeState}>
                    {item.state}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
