import * as type from "../actoins/type";

const initialState = {
  navSelectedIndex: 0,
  topTitle: "",
};

const layout = (state = initialState, action) => {
  switch (action.type) {
    case type.NAV_SELECTED: // 하단 버튼 hover 변경
      return {
        ...state,
        navSelectedIndex: action.selectedIndex,
      };
    case type.TOP_TITLE: // 하단 버튼 hover 변경
      return {
        ...state,
        topTitle: action.title,
      };
    default:
      return state;
  }
};

export default layout;
