import React from 'react'
import Mydiv from "./MyDiv"
import MydivM from "./MyDivM"

export default function MyDivs() {
  return (
    <div>
      <MydivM title="제목"/>
      {/* <Mydiv /> */}
      {/* <Mydiv /> */}
    </div>
  )
}
