import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import ForumIcon from "@material-ui/icons/Forum";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ListAltIcon from "@material-ui/icons/ListAlt";
import GroupIcon from "@material-ui/icons/Group";
import { Mail as MailIcon, Inbox as InboxIcon } from "@material-ui/icons";

import useSocket from "../hooks/useSocket";

import AccountCircle from "@material-ui/icons/AccountCircle";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as Util from "../common/Utils";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar : {
    backgroundColor : theme.palette.primary.light,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: "240px",
    flexShrink: 0,
  },
  menulist: {
    width: "240px",
  },
  titleMargin: {
    margin: "20px 0",
  },
  alert: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "100%",
    zIndex: "9999",
  },
}));

export default withRouter(function TopBar({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuPopShow = Boolean(anchorEl);
  const [isLeftMenu, setIsLeftMenu] = React.useState(false);
  const [needLogin, setNeedLogin] = useState(false);
  const navUrl = "/" + window.location.pathname.split("/").slice(1).join("/");
  const { isLogin, user } = useSelector((state) => state.user);
  const { pushOnOff, pushTime } = useSelector((state) => state.config); // config 의 state 를 가져오기!
  const { navSelectedIndex , topTitle} = useSelector(state => state.layout)
  const [socket, disconnect] = useSocket("chat");
  // console.log(isLogin,user);
  // const [isLogin, setisLogin] = React.useState(store.getState().isLogin);

  const onLogin = () => {
    // history.push("/login");
    // console.log("url" , window.location.pathname.split("/")[1]);
    history.push("/login", { footNav: navUrl });
  };

  // store.subscribe(()=>{
  //   console.log("tobbar",1,isLogin);
  //   setisLogin(store.getState().isLogin);
  // })

  useEffect(() => {
    const androidID = document.querySelector("input[name=android_id").value;
    // alert(androidID);
    // 안드로이드 정보가 있고 로그인 되어 있을때 push 설정값 디비 저장!
    if (androidID != "") {
      if (isLogin && user.loginGubun && user.id) {
        // alert(isLogin);
        axios
          .post(
            `${process.env.REACT_APP_BACK_URL}/api/setIdPush`,
            {
              loginGubun: user.loginGubun,
              loginID: user.id,
              androidID: androidID,
              pushOnOff: pushOnOff,
              pushTime: pushTime,
            },
            { withCredentials: true }
          )
          .then((res) => {
            // console.log(res);
            document.querySelector("input[name=android_id").value = "";
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    }
  });

  const getLoginInfo = () => {
    // console.log("topbar getLoginInfo" , isLogin)
    // console.log("andid" , document.querySelector("input[name=android_id").value)
    if (isLogin) {
      axios
        .post(`${process.env.REACT_APP_BACK_URL}/api/myInfo`, "", { withCredentials: true })
        .then((res) => {
          // console.log(res.data);
          const userLoginGubun = "kakao";
          const userid = res.data.rdata.id;
          if (res.data.rdata.kakao_account && res.data.rdata.kakao_account.profile) {
            const { nickname, profile_image_url, thumbnail_image_url } = res.data.rdata.kakao_account.profile;
            dispatch({
              type: "SET_USERINFO",
              user: {
                loginGubun: userLoginGubun,
                id: userid,
                nickname: nickname,
                profile_image_url: profile_image_url,
                thumbnail_image_url: thumbnail_image_url,
              },
            });

            //로그인정보를 잘 가져왔다면 config 정보도 가져옴.
            // console.log("getConfig start");
            axios
              .get(`${process.env.REACT_APP_BACK_URL}/api/config/${userLoginGubun}/${userid}`, "", { withCredentials: true })
              .then((_res) => {
                // console.log("getConfig then");
                // console.log(_res.data);
                if (_res.data && _res.data.pushTime) {
                  // console.log("getConfig then2");
                  dispatch({ type: "SET_PUSHONOFF", pushOnOff: _res.data.pushOnOff });
                  dispatch({ type: "SET_PUSHTIME", pushTime: _res.data.pushTime });
                  // console.log("getConfig then3");
                }
              })
              .catch((_err) => {
                // console.log("getConfig error");
                console.log(_err);
              });
          } else {
            // 로그인 정보 날리기!!
            dispatch({
              type: "CLEAR_USERINFO",
            });
          }
        })

        // res.data.rdata.nickname
        //res.data.rdata.profile_image_url
        .catch((err) => {
          // 로그인 정보 날리기!!
          console.error(err);
          dispatch({
            type: "CLEAR_USERINFO",
          });
        });
    }
  };

  useEffect(() => {
    getLoginInfo();
  }, [isLogin]);

  const onMenuPop = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    history.push("/logout");
  };

  const handleProfile = () => {
    setAnchorEl(null);
    history.push(`/Profile/${user.loginGubun}/${user.id}`);
  };

  const handleConfig = () => {
    setAnchorEl(null);
    history.push("/config");
  };

  const onLeftMenuOpen = () => {
    setIsLeftMenu(true);
  };

  const onLeftMenuClose = () => {
    setIsLeftMenu(false);
  };

  const onGoinviteFriend = () => {
    console.log("onGoinviteFriend");
    setIsLeftMenu(false);
    if (false == window.Kakao.isInitialized()) {
      window.Kakao.init("217aef66389e1e1e4dd640f461544ef3");
      window.Kakao.isInitialized();
    }
    window.Kakao.Link.sendCustom({
      templateId: 49461, // 다이어리초대 (49461)
      templateArgs: {
        title: "제목 영역입니다.",
        description: "설명 영역입니다.",
      },
    });
  };

  const onGoToDo = () =>{
    setIsLeftMenu(false);
    if (isLogin && user.loginGubun && user.id) {
      history.push("/todo");    
    } else {
      setNeedLogin(true);
      setTimeout(() => {
        setNeedLogin(false);
      }, 1500);
    }    
  }

  const onGoThankyou = () =>{
    setIsLeftMenu(false);
    history.push("/");
  }

  const onGoChatRoomList = () => {
    setIsLeftMenu(false);
    if (isLogin && user.loginGubun && user.id) {
      history.push("/chat");
    } else {
      setNeedLogin(true);
      setTimeout(() => {
        setNeedLogin(false);
      }, 1500);
    }
  };

  // console.log("tobbar", 2, isLogin);

  // 로그인 정보 생기면 online 쏘기!
  useEffect(() => {
    if (isLogin && socket.id && user.id) {
      // console.log(socket.id);
      // console.log("useEffect - online");
      socket.emit("online", Object.assign(user, { socketID: socket.id }));
    }
  }, [isLogin, socket, user]);

  // 사라질때 disconnect
  useEffect(() => {
    // console.log("useEffect - disconnect ");
    return () => {
      socket.emit("offline", user);
      if ("function" == typeof window.disconnect) {
        // console.log("useEffect - disconnect call");
        disconnect();
      }
    };
  }, [disconnect]);

  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onLeftMenuOpen}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={isLeftMenu} onClose={onLeftMenuClose} className={classes.drawer}>
            <Typography variant="subtitle1" align="center" className={classes.titleMargin}>
              엠엔엠 다이어리
            </Typography>
            <List className={classes.menulist}>
              <ListItem button onClick={onGoThankyou}>
                <ListItemIcon>
                  <FavoriteIcon />
                </ListItemIcon>
                <ListItemText primary={"3줄 일기"} />
              </ListItem>
              <ListItem button onClick={onGoChatRoomList}>
                <ListItemIcon>
                  <ForumIcon />
                </ListItemIcon>
                <ListItemText primary={"실시간 채팅"} />
              </ListItem>
              <Divider />
              <ListItem button onClick={onGoToDo}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary={"일정관리"} />
              </ListItem>
              <Divider />
              <ListItem button onClick={onGoinviteFriend}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={"친구초대"} />
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary={"랭킹"} />
              </ListItem>
            </List>
          </Drawer>
          <Typography variant="h6" className={classes.title}>
            {topTitle}
          </Typography>
          {!isLogin && (
            <Button onClick={onLogin} color="inherit">
              로그인
            </Button>
          )}
          {isLogin && (
            <>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={onMenuPop}
              >
                <Avatar alt="user.nickname" src={Util.httpToHttps(user.thumbnail_image_url)} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id="loginMenu"
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuPopShow}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleProfile}>프로필</MenuItem>
                <MenuItem onClick={handleConfig}>푸시설정</MenuItem>
                <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {needLogin && (
        <div className={classes.alert}>
          <Alert severity="error" color="error">
            로그인후 이용가능합니다.
          </Alert>
        </div>
      )}
    </div>
  );
});
