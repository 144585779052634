export function getFormatDate(date) {
  var year = date.getFullYear();              //yyyy
  var month = (1 + date.getMonth());          //M
  month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
  var day = date.getDate();                   //d
  day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
  var hour = date.getHours();
  hour = hour >= 10 ? hour : '0' + hour;      //hour 두자리로 저장
  var minute = date.getMinutes();
  minute = minute >= 10 ? minute : '0' + minute;          //minute 두자리로 저장
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;       //'-' 추가하여 yyyy-mm-dd hh:mm 형태 생성 가능
}

export const copyObj = (obj) => {
  var copy = {};
  if (Array.isArray(obj)) {
    copy = obj.slice().map((v) => {
      return copyObj(v);
    });
  } else if (typeof obj === "object" && obj !== null) {
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = copyObj(obj[attr]);
      }
    }
  } else {
    copy = obj;
  }
  return copy;
};

export const httpToHttps = (url) =>{
  return url.replace("http://","https://");
}