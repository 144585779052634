import React from 'react'
import FootNav from '../components/FootNav'

export const Footer = () => {
    return (
        <>
            <div>
                <FootNav  />
            </div>
        </>
    )
}
