import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  rightButton : {
    display:'flex',
    justifyContent:'flex-end',
  },
}));

export default function Friends() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [friendList, setFriendList] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getFriendList = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACK_URL}/api/friendlist`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res);
        setFriendList(res.data.rdata.elements);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSendInvite = () =>{
    axios
      .post(
              `${process.env.REACT_APP_BACK_URL}/api/sendinvite`,
              {
                uuids : checked
              },
              {
                withCredentials : true
              },
      )
      .then(res =>{
        console.log(res);
      })
      .catch(err =>{
        console.error(err);
      })
      

  }



  useEffect(() => {
    getFriendList();
  }, []);

  return (
    <>
      {/* <div>
        <TextField fullWidth label="닉네임을 검색해보세요." margin="normal" variant="outlined" />
      </div> */}
      <div className={classes.rightButton}>
        <Button variant="contained" color="primary" className={classes.button} endIcon={<Icon>send</Icon>} onClick={onSendInvite}>
          초대
        </Button>
      </div>
      <List dense className={classes.root}>
        {friendList.map((friend) => {
          return (
            <>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar alt={friend.profile_nickname} src={friend.profile_thumbnail_image} />
                </ListItemAvatar>
                <ListItemText id={friend.profile_nickname} primary={friend.profile_nickname} />
                <ListItemSecondaryAction>
                  <Checkbox onChange={handleToggle(friend.uuid)} checked={checked.indexOf(friend.uuid) !== -1} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar alt={friend.profile_nickname} src={friend.profile_thumbnail_image} />
                </ListItemAvatar>
                <ListItemText id={friend.profile_nickname} primary={friend.profile_nickname} />
                <ListItemSecondaryAction>
                  <Checkbox onChange={handleToggle(friend.uuid)} checked={checked.indexOf(friend.uuid) !== -1} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar alt={friend.profile_nickname} src={friend.profile_thumbnail_image} />
                </ListItemAvatar>
                <ListItemText id={friend.profile_nickname} primary={friend.profile_nickname} />
                <ListItemSecondaryAction>
                  <Checkbox onChange={handleToggle(111)} checked={checked.indexOf(111) !== -1} />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar alt={friend.profile_nickname} src={friend.profile_thumbnail_image} />
                </ListItemAvatar>
                <ListItemText id={friend.profile_nickname} primary={friend.profile_nickname} />
                <ListItemSecondaryAction>
                  <Checkbox onChange={handleToggle(11)} checked={checked.indexOf(11) !== -1} />
                </ListItemSecondaryAction>
              </ListItem>
            </>
          );
        })}
      </List>
    </>
  );
}
