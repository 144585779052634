import * as type from '../actoins/type';

const initialState = {
  pushOnOff : true,
  pushTime : '20:30',
  androidID : '',
  pushKey : '',
};

const config = (state = initialState, action) => {

  
  switch (action.type) {
    case type.SET_PUSHONOFF: // 로그인 상태 변경
      return {
        ...state, pushOnOff: action.pushOnOff
      };
    case type.SET_PUSHTIME:
      return {
        ...state, pushTime: action.pushTime
      };
    case type.SET_PUSHKEY:
      return {
        ...state, androidID: action.androidID , pushKey : action.pushKey
      };    
    default:
      return state
  }
};

export default config;