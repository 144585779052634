import { BrowserRouter , Route , Switch } from 'react-router-dom';
import Login from "./components/Login"
import Logout from "./components/Logout"
import Oauth from "./components/Oauth"
import ThankYouList from "./components/ThankYouList"
import ThankYouWrite from "./components/ThankYouWrite"
import ThankYouLike from "./components/ThankYouLike"
import Profile from "./components/Profile"
import Config from "./components/Config"
import SetKey from "./components/SetKey"
import Friends from "./components/Friends"
import Todo from "./components/Todo"
import NoMatch from "./components/NoMatch"
import Message from "./components/Message"
import Chat from "./components/Chat"
import MyDivs from "./components/MyDivs"
import Grid from '@material-ui/core/Grid';
import { Layout } from './Layouts/Layout';
import { Provider } from "react-redux";
import store from './store/store'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';


function App() {

  const persistor = persistStore(store);
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Grid container direction="row"  justify="center"  alignItems="center" spacing={0} >
            <Grid item xs={12} >
              <Layout>
                
                  <Switch>   {/* 가장처음 매칭되는것 하나만 보여지는 방식 */}
                    <Route exact path="/" component={ThankYouList}></Route>
                    <Route path="/write" component={ThankYouWrite}></Route>
                    <Route path="/edit/:postid" component={ThankYouWrite}></Route>
                    <Route path="/like" component={ThankYouLike}></Route>
                    <Route path="/profile/message/:loginGubun/:loginId" component={Message}></Route>
                    <Route path="/Chat" component={Chat}></Route>
                    <Route path="/profile/:loginGubun/:loginId" component={Profile}></Route>
                    <Route path="/config" component={Config}></Route>
                    <Route path="/login" component={Login}></Route>
                    <Route path="/logout" component={Logout}></Route>
                    <Route path="/oauth" component={Oauth}></Route>
                    <Route path="/setKey" component={SetKey}></Route>
                    <Route path="/Friends" component={Friends}></Route>
                    <Route path="/todo" component={Todo}></Route>
                    <Route path="/mydivs" component={MyDivs}></Route>
                    <Route component={NoMatch}></Route>
                  </Switch>
                
              </Layout>
            </Grid>
          </Grid>    
        </BrowserRouter>       
      </PersistGate>     
    </Provider>
  );
}

export default App;
