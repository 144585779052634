import React , { useEffect , useState} from 'react'
import {useSelector , useDispatch} from 'react-redux';

import { makeStyles  , List , ListItem , ListItemText , ListItemAvatar , Avatar } from '@material-ui/core';
import { Image as ImageIcon }from '@material-ui/icons';
import * as Util from "../common/Utils";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));

export default function ThankYouLikeChild() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [likeUser,setLikeUser] = useState([]);
    const { user } = useSelector(state => state.user)
    

    const getLikeUser = () => {
        axios
            .get(`${process.env.REACT_APP_BACK_URL}/api/profile/likeuser/${user.loginGubun}/${user.id}`)
            .then(res =>{
                console.log(res.data);
                setLikeUser(res.data);
            })
            .catch(err =>{
                console.log(err);
            })
    }

    useEffect(()=>{
        dispatch({ type: "NAV_SELECTED", selectedIndex: 2 }); 
        dispatch({ type: "TOP_TITLE", title: "좋아요누른 사용자" }); 
    },[])

    useEffect(() => {
        getLikeUser();
    }, [])


    if(likeUser.error){
        return (
            <div>아직 좋아요를 한 사용자가 없습니다.</div>
        )
    }


    return (
        <div>
            <List className={classes.root}>
                {likeUser.map(item =>{
                    return (
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar alt={item.nickname} src={Util.httpToHttps(item.thumbnail_image_url)} />
                            </ListItemAvatar>
                            <ListItemText primary={item.nickname} secondary={item.introduce} />
                        </ListItem>
                    )
                })}             
            </List>
        </div>
    )
}
