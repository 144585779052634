import React , {useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useDispatch , useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios'



const useStyles = makeStyles((theme) => ({
    world : {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'30px'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin:'10px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    HeaderCover:{
      display:'flex' ,
      flexDirection:'column',
      justifyContent:'center',
      alignItems: 'center'
    },
    HeaderCopy:{
        width:'80%',
        textAlign:'center',
    },
    alert : {
        top: `40%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,        
        position: 'absolute',
        width: '100%',        
    }    
}));

export default function Config({history}) {

    const dispatch = useDispatch()
    const classes = useStyles();
    const [alertShow,setAlertShow] = useState(false);
    const { isLogin , user } = useSelector(state => state.user) // user 의 state 를 가져오기!
    const { pushOnOff , pushTime } = useSelector(state => state.config) // config 의 state 를 가져오기!

    const onPushChange = () =>{
        dispatch({type:"SET_PUSHONOFF",pushOnOff:!pushOnOff}); 
    }

    const onPushTimeChange = (e) =>{
        // console.log("onPushTimeChange");
        // console.log(e.target.value)
        dispatch({type:"SET_PUSHTIME",pushTime:e.target.value});         
    }

    useEffect(async () => {
        if(!isLogin){
            const navUrl = "/" + window.location.pathname.split("/")[1];
            history.push("/login",{ footNav: navUrl })    
        }
    }, [])    

    const onSaveConfig = () =>{
        // console.log(pushOnOff);
        axios.post(`${process.env.REACT_APP_BACK_URL}/api/config`,{
            loginGubun : user.loginGubun,
            loginID: user.id,
            pushOnOff: pushOnOff,
            pushTime: pushTime,
        })
            .then(res =>{
                // console.log("result",res.data.result)
                if(res.data.result == "1"){
                    // console.log("result")
                    setAlertShow(true);
                    setTimeout(() => {
                        setAlertShow(false);
                    }, 1500);                    
                }else{
                    // 저장에 실패하였습니다. // 이전데이터 표시!?
                }
            })
            .catch(console.error)
    }

    return (

        <div className={classes.world}>
          
            
            <form className={classes.container} noValidate>
                <Grid container direction="row"  justify="center"  alignItems="center" spacing={0} >
                    <Grid className={classes.HeaderCover}>
                        <Typography component="div" className={classes.HeaderCopy}>
                            매일감사일기를 쓰게 되면 행복지수가 무려 75% 높아진다는 연구결과가 있습니다.
                        </Typography>
                        <br />
                        <Typography component="div" className={classes.HeaderCopy}>
                            감사일기를 작성할 시간을 설정하여 꾸준히 작성해보세요
                        </Typography>        
                        <br />
                        <Typography component="div" className={classes.HeaderCopy}>
                            해당시간에 푸시메시지를  통해 알려드리겠습니다.
                        </Typography>                                         
                    </Grid>                    
                    <Grid item xs={12} style={{margin:'40px'}}></Grid>                    
                    <Grid>
                        <FormControlLabel
                            control={<Checkbox checked={pushOnOff} onChange={onPushChange} name="pushOnOff" />}
                            label="푸시알림 사용여부"
                        />
                    </Grid>                    
                    <Grid item xs={12} style={{margin:'10px'}}></Grid>
                    <Grid>
                        <TextField
                            id="pushTime"
                            label="푸시알림 시간"
                            type="time"
                            defaultValue={pushTime}
                            className={classes.textField}
                                InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            value={pushTime}
                            onChange={onPushTimeChange}
                        />
                    </Grid>
                    <Grid item xs={12} style={{margin:'20px'}}></Grid>
                    <Grid>
                        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveConfig}>
                            저 장
                        </Button>                
                    </Grid>
                </Grid>
                </form>
                { alertShow && (
                <div className={classes.alert}>
                    <Alert severity="success" color="info" >
                        정상적으로 등록되었습니다.
                    </Alert>         
                </div>
            )}                
        </div>
    )
}
