import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/core/styles";
import { copyObj } from "../common/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 70px)",
    // border:'1px solid black'
  },
  mydiv: {
    margin: "25px",
    padding: "20px",
    minHeight: "200px",
    height: "15%",
    border: "1px solid black",
    borderTopLeftRadius: "255px 15px",
    borderTopRightRadius: "15px 255px",
    borderBottomRightRadius: "225px 15px",
    borderBottomLeftRadius: "15px 255px",
    boxSizing: "border-box",
    marginBottom: "1%",
    position: "relative",
    "& .boxTitle": {
      position: "absolute",
      top: "-10px",
      left: "5px",
      background: "white",
      padding: "0px 15px",
    },
  },
  itemList: {
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    // border: "1px solid black",
  },
  itemCover: {
    display: "inline-block",
    marginBottom: "1px",
  },
  item: {
    border: "1px solid black",
    display: "flex",
    justifyContent: "space-around",
    marginRight: "10px",
    "& .itemTitle": {
      fontSize: "0.9rem",
      width: "110px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      padding: "0px 10px",
    },
    "& .itemState1": {
      width: "15px",
      textAlign: "center",
      backgroundColor: "#32df24",
    },
    "& .itemState2": {
      width: "15px",
      textAlign: "center",
      backgroundColor: "#ff0f24",
    },
  },
  itemTest: {
    height: "100%",
    border: "1px solid black",
  },
}));

export default function MyDiv(props) {
  const classes = useStyles();
  const [todayData, setTodayData] = useState([]);
  const [tomorrowData, setTomorrowData] = useState([]);

  const onShowContent = () => {
    console.log(1);
  };

  const onChangeState = () => {
    console.log(2);
  };

  const removeData = (dataGubun, id) => {
    let newData;
    let delIndex;
    let returnObject;


    console.log("dataGubun" , dataGubun)
    console.log("id" , id)
    switch (dataGubun) {
      case "today":
        newData = [...todayData];
        delIndex = newData.findIndex((item) => item.id == id);
        console.log("delIndex",delIndex)
        returnObject = copyObj(newData[delIndex]);
        console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setTodayData(newData);
        break;
      case "tomorrow":
        newData = [...tomorrowData];
        delIndex = newData.findIndex((item) => item.id == id);
        returnObject = copyObj(newData[delIndex]);
        console.log(returnObject);
        if (delIndex >= 0) {
          newData.splice(delIndex, 1);
        }
        setTomorrowData(newData);

        break;
    }

    console.log("returnObject",returnObject);
    return returnObject;
  };

  const addData = (dataGubun, addObj) => {
    let newData;

    switch (dataGubun) {
      case "today":
        newData = [...todayData, addObj];
        setTodayData(newData);
        break;
      case "tomorrow":
        newData = [...tomorrowData, addObj];
        setTomorrowData(newData);
        break;
    }
  };

  const dragStart = (e) => {
    let containerGubun;
    if (e.target.closest(".drop-target")) {
      // console.log(".drop-target in")
      // console.log("e.target.closest(.drop-target).classList" , e.target.closest(".drop-target").classList)
      if (e.target.closest(".drop-target").classList.contains("today")) {
        containerGubun = "today";
      }
      if (e.target.closest(".drop-target").classList.contains("tomorrow")) {
        containerGubun = "tomorrow";
      }
      console.log("containerGubun" , containerGubun);
      e.dataTransfer.setData("sourceGubun", containerGubun); // dataTransfer.setData
      e.dataTransfer.setData("itemID", e.target.id); // dataTransfer.setData
      e.dataTransfer.setData("1", e.target.id); // dataTransfer.setData
    }
  };

  const draging = (e) => {
    e.preventDefault();
    if (e.target.closest(".drop-target")) {
      e.target.closest(".drop-target").style.borderWidth = "2px";
      e.target.closest(".drop-target").style.borderStyle = "dashed";
    }
  };

  const dragEnd = (e) => {
    e.preventDefault(); // 기본적으로 서로 다른 요소는 drop 이 안되는데 drop 가능하게 할려고!!
    // console.log(e.target.closest(".drop-target"))
    if (e.target.closest(".drop-target")) {
      e.target.closest(".drop-target").style.borderWidth = "1px";
      e.target.closest(".drop-target").style.borderStyle = "solid";
    }
  };

  const drop = (e) => {
    e.preventDefault();

    const souceGubun = e.dataTransfer.getData("sourceGubun");
    const souceID = e.dataTransfer.getData("itemID");
    let targetGubun;

    // console.log("drop in");
    if (e.target.closest(".drag-container")) {
      // console.log("drag-container in");
      if (e.target.closest(".drop-target").classList.contains("today")) {
        targetGubun = "today";
      }
      if (e.target.closest(".drop-target").classList.contains("tomorrow")) {
        targetGubun = "tomorrow";
      }

      if (e.target.closest(".drop-target")) {
        e.target.closest(".drop-target").style.borderWidth = "1px";
        e.target.closest(".drop-target").style.borderStyle = "solid";
      }

      console.log("souceGubun", souceGubun);
      console.log("souceID", souceID);
      console.log("targetGubun", targetGubun);

      if (targetGubun !== souceGubun && souceGubun && targetGubun && souceID) {
        addData(targetGubun, removeData(souceGubun, souceID));
      }
    }
  };

  useEffect(() => {
    setTodayData([
      {
        id: 1,
        content: "라온이와 놀아주기",
        state: "O",
      },
      {
        id: 2,
        content: "REACT 공부하기",
        state: "O",
      },
      {
        id: 3,
        content: "몽고디비 마스터 하기",
        state: "O",
      },
      {
        id: 4,
        content: "캘리 그리기",
        state: "X",
      },
    ]);
  }, []);

  return (
    <div className={classes.root}>
      <div className={`${classes.mydiv} drop-target today`} onDrop={drop} onDragOver={draging} onDragLeave={dragEnd}>
        <div className="boxTitle">{props.title}</div>
        <div className={`${classes.itemList} drag-container `}>
          {todayData.map((item) => {
            return (
              <div className={classes.itemCover} key={item.id} id={item.id} draggable="true" onTouchStart={dragStart} onDragStart={dragStart}>
                <div className={classes.item}>
                  <div className="itemTitle" onClick={onShowContent}>
                    {item.content}
                  </div>
                  <div className={item.state === "O" ? "itemState1" : "itemState2"} onClick={onChangeState}>
                    {item.state}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`${classes.mydiv} drop-target tomorrow`} onDrop={drop} onDragOver={draging} onDragLeave={dragEnd}>
        <div className={`${classes.itemList} drag-container `}>
        {tomorrowData.map((item) => {
            return (
              <div className={classes.itemCover} key={item.id} id={item.id} draggable="true" onDragStart={dragStart}>
                <div className={classes.item}>
                  <div className="itemTitle" onClick={onShowContent}>
                    {item.content}
                  </div>
                  <div className={item.state === "O" ? "itemState1" : "itemState2"} onClick={onChangeState}>
                    {item.state}
                  </div>
                </div>
              </div>
            );
          })}          

        </div>
      </div>
    </div>
  );
}
