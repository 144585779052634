import React , { useEffect } from 'react'
import axios from 'axios';
import {useDispatch} from 'react-redux'


export default function Logout({history,location}) {

    const dispatch = useDispatch()

    const logout = () =>{        
        axios.post(`${process.env.REACT_APP_BACK_URL}/api/logout`,"",{
            withCredentials: "true"
        })
            .then(res=>{
                if(res.data.rdata === "ok"){
                    // console.log("call CLEAR_USERINFO");
                    dispatch({type:"CLEAR_USERINFO"})
                    dispatch({type:"NAV_SELECTED",selectedIndex:0}); // 첫페이지로
                    history.push("/");
                }else{
                    // console.log("error but call CLEAR_USERINFO");
                    dispatch({type:"CLEAR_USERINFO"})
                    dispatch({type:"NAV_SELECTED",selectedIndex:0}); // 첫페이지로
                    history.push("/");
                    
                }
            })
    }

    useEffect(() => {
        logout()
    }, [])

    return (
        <>
        </>
    )
}
