import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Comment from '@material-ui/icons/Comment';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Create from '@material-ui/icons/Create';
import { AppBar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles({
  root: {
    minWidth: 260,
    backgroundColor:'#dedede',
  },
});

export default withRouter(function FootNav({history}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { navSelectedIndex } = useSelector(state => state.layout)
  // const [value, setValue] = React.useState(store.getState().navSelectedIndex);
  
  // store.subscribe(()=>{
  //   setValue(store.getState().navSelectedIndex);
  // })

  // console.log("navSelectedIndex",navSelectedIndex)


  if(navSelectedIndex > 3 || navSelectedIndex < 0){
    return (
      <></>
    )
  }

  return (
    <AppBar position="fixed" style={{
        top:'auto', bottom:0 
    }}>
      <BottomNavigation
        value={navSelectedIndex}
        onChange={(event, newValue) => {
          dispatch({type:"NAV_SELECTED",selectedIndex:newValue}); // hover 효과 때문에 사용!
          switch (newValue){
            case 0 : 
              history.push("/");
              break;
            case 1 : 
              history.push("/write");
              break;
            case 2 : 
              history.push("/like");
              break;                            
          }

          
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction label="목록" icon={<Comment />} />
        <BottomNavigationAction label="쓰기" icon={<Create />} />
        <BottomNavigationAction label="좋아요" icon={<FavoriteIcon />} />
      </BottomNavigation>
    </AppBar>
  );
})
