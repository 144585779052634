import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import HomeIcon from "@material-ui/icons/Home";
import EmailIcon from "@material-ui/icons/Email";
import FavoriteIcon from "@material-ui/icons/Favorite";
import EditIcon from "@material-ui/icons/Edit";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import CreateIcon from "@material-ui/icons/Create";
import CreateTwoToneIcon from "@material-ui/icons/CreateTwoTone";

import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbUpAltTwoToneIcon from "@material-ui/icons/ThumbUpAltTwoTone";

import ThankYouCard from "./ThankYouCard";
import { Redirect, Route, withRouter } from "react-router-dom";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import useSWR from "swr";

import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: theme.palette.background.paper,
  },
  ProfileTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainImg: {
    flex: 3,
  },
  mainText: {
    flex: 7,
  },
  between: {
    display: "flex",
    justifyContent: "space-between",
  },
  ProfileTop3: {
    display: "flex",
    justifyContent: "start",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  sizeMiddle: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  fc: {
    display: "flex",
    alignItems: "center",
  },
  fcc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textMiddle: {
    fontSize: "1.5rem",
  },
  divider: {
    marginTop: "20px",
  },

  alert: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "100%",
  },
  editSaveBtn: {
    marginTop : '10px',
    display:'block'
  },
}));

export default withRouter(function Profile({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const aloginGubun = match.params.loginGubun;
  const aloginId = match.params.loginId;

  const [editNickName, setEditNickName] = useState("");
  const [editIntroduce, setEditIntroduce] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [needLogin, setNeedLogin] = useState(false);
  const { isLogin } = useSelector((state) => state.user); // user 의 state 를 가져오기!
  const { user } = useSelector((state) => state.user);

  dispatch({ type: "NAV_SELECTED", selectedIndex: 4 }); // 없는페이지로!
  dispatch({ type: "TOP_TITLE", title: "프로필" });

  const fetcher = async (url) => {
    const resp = await axios(url);
    //console.log(resp.data);
    return resp.data;
  };

  const { data: myInfo, mutate } = useSWR(`${process.env.REACT_APP_BACK_URL}/api/profile/${aloginGubun}/${aloginId}`, fetcher);
  const { data: myPosts } = useSWR(`${process.env.REACT_APP_BACK_URL}/api/posts/author/${aloginGubun}/${aloginId}`, fetcher);
  const { data: myLikePosts } = useSWR(`${process.env.REACT_APP_BACK_URL}/api/posts/mylike/${aloginGubun}/${aloginId}`, fetcher);
  const { data: myLikeUser } = useSWR(`${process.env.REACT_APP_BACK_URL}/api/profile/likeuser/${aloginGubun}/${aloginId}`, fetcher);
  // console.log("myinfo-data",myInfo);
  // console.log("myPosts",myPosts);
  // console.log("myLikePosts", myLikePosts);
  // console.log("myLikeUser", myLikeUser);

  if (myInfo && myInfo.likes && user) {
    //console.log("in")
    const likeMeCheck = myInfo.likes.findIndex((likeUser) => {
      // console.log("likeUser",likeUser)
      // console.log("user",user)
      return likeUser.loginGubun == user.loginGubun && likeUser.id == user.id && !user.id == "";
    });
    // console.log(likeMeCheck);
    if (likeMeCheck >= 0) {
      mutate({ ...myInfo, likeMe: true }, false); //
    }
  }
  // console.log("myinfo",myInfo);

  const addLikeme = () => {
    if (isLogin) {
      axios
        .put(`${process.env.REACT_APP_BACK_URL}/api/profile/like/${aloginGubun}/${aloginId}`, {
          liker: user,
        })
        .then((res) => {
          //console.log(res);
          let newMyInfo = { ...myInfo, likeMe: true };
          if (newMyInfo.likes) {
            newMyInfo.likes.push(user);
          } else {
            newMyInfo.likes = [];
            newMyInfo.likes.push(user);
          }
          mutate(newMyInfo);
          // console.log(myInfo);
        });
    } else {
      setNeedLogin(true);
      setTimeout(() => {
        setNeedLogin(false);
      }, 1500);
    }
  };

  const removeLikeme = () => {
    if (isLogin) {
      axios
        .put(`${process.env.REACT_APP_BACK_URL}/api/profile/dislike/${aloginGubun}/${aloginId}`, {
          liker: user,
        })
        .then((res) => {
          // console.log(res);
          let newMyInfo = { ...myInfo, likeMe: false };
          let removeUserIdx = newMyInfo.likes.findIndex(function (item) {
            return (item.loginGubun = user.loginGubun && item.id == user.id);
          });
          newMyInfo.likes.splice(removeUserIdx, 1);
          mutate(newMyInfo);
          // console.log(myInfo);
        });
    }
  };

  const onEditProfile = () => {
    setEditNickName(myInfo.nickname);
    setEditIntroduce(myInfo.introduce);
    setEditMode(true);
  };

  const onSaveProfile = () => {
    // console.log(aloginGubun);
    // console.log(aloginId);
    axios
      .put(`${process.env.REACT_APP_BACK_URL}/api/profile/${aloginGubun}/${aloginId}`, {
        nickName: editNickName,
        introduce: editIntroduce,
      },{
        withCredentials : true
      })
      .then((res) => {
        // console.log(res);
        setEditMode(false);
        mutate({...myInfo, nickName: editNickName , introduce:editIntroduce})
      })
      .catch((err) => {
        setEditMode(false);
      });
  };

  const onChangeName = (e) => {
    setEditNickName(e.target.value);
  };

  const onChangeIntroduce = (e) => {
    setEditIntroduce(e.target.value);
  };

  const getGA = () =>{
    const pathName = window.location.pathname;
    ReactGA.initialize("UA-173628111-2");
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);    
  }

  useEffect(() => {
    getGA();
  }, []);  

  if (!myInfo) {
    return <>로딩중</>;
  }

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.ProfileTop}>
          <div className={classes.mainImg}>
            <Avatar alt={myInfo.nickname} src={myInfo.profile_image_url} className={classes.large} />
          </div>
          <div className={classes.mainText}>
            {editMode ? (
              <>
                <div className={classes.between}>
                  <Typography variant="h5" component="h5" nowrap>
                  <TextField id="nickname" name="nickname" label="nickname" 
                            value={editNickName} onChange={onChangeName}
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }} ></TextField>
                    <Typography variant="span" component="span" nowrap>
                      &nbsp;&nbsp;
                    </Typography>
                    {/* 다음에 홈페이지 및 이메일 기능 추가!? */}
                    {/* <HomeIcon /> */}
                    {/* <EmailIcon /> */}
                  </Typography>
                </div>
                <Typography variant="h6" component="h6" nowrap>
                  <TextField id="introduce" name="introduce" label="introduce" 
                            value={editIntroduce} onChange={onChangeIntroduce}
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }} ></TextField>
                </Typography>
                <Typography variant="span" component="span" color="textSecondary" nowrap className={classes.editSaveBtn} onClick={onSaveProfile}>
                  <Button variant="contained" color="primary">
                    저장
                  </Button>
                </Typography>
              </>
            ) : (
              <>
                <div className={classes.between}>
                  <Typography variant="h5" component="h5" nowrap>
                    {myInfo.nickname}
                    <Typography variant="span" component="span" nowrap>
                      &nbsp;&nbsp;
                    </Typography>
                    {/* 다음에 홈페이지 및 이메일 기능 추가!? */}
                    {/* <HomeIcon /> */}
                    {/* <EmailIcon /> */}
                  </Typography>
                  <Typography variant="h5" component="h5" color="textSecondary" nowrap className={classes.fc} onClick={onEditProfile}>
                    <EditIcon />
                  </Typography>
                </div>

                <Typography variant="h6" component="h6" nowrap>
                  {myInfo.introduce}
                </Typography>
              </>
            )}

            <div className={classes.ProfileTop3}>
              <Typography variant="div" component="div" nowrap>
                {myInfo.likeMe ? <ThumbUpAltTwoToneIcon onClick={removeLikeme} /> : <ThumbUpAltOutlinedIcon onClick={addLikeme} />}
              </Typography>
              <Typography variant="span" component="span" nowrap>
                &nbsp;
              </Typography>
              <Typography variant="div" component="div" nowrap className={classes.fcc}>
                {myInfo.likes && myInfo.likes.length}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <div className={classes.fcc}>
            <CreateTwoToneIcon className={classes.sizeMiddle} />
            <div className={classes.textMiddle}>{myPosts && myPosts.length ? myPosts.length : 0}</div>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <FavoriteTwoToneIcon className={classes.sizeMiddle} />
            <div className={classes.textMiddle}>{myLikePosts && myLikePosts.length ? myLikePosts.length : 0}</div>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <ThumbUpAltTwoToneIcon className={classes.sizeMiddle} alt="나를 추천한 사용자" />
            <div className={classes.textMiddle}>{myLikeUser && myLikeUser.length ? myLikeUser.length : 0}</div>
          </div>
        </div>
      </div>
      <Divider variant="middle" className={classes.divider} />
      <div>
        <ThankYouCard gubun="sel" selUser={{ loginGubun: aloginGubun, id: aloginId }} />
      </div>
      {needLogin && (
        <div className={classes.alert}>
          <Alert severity="error" color="error">
            로그인후 이용가능합니다.
          </Alert>
        </div>
      )}
    </div>
  );
});
