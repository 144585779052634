import { combineReducers } from 'redux'; // 흩어진 reducer 들을 하나로 모아준다.

import layout from './layout';
import user from './user';
import config from './config';


const rootReducer = combineReducers({
    layout,
    user,
    config,
});

export default rootReducer;