import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import useSWR, { useSWRInfinite } from "swr";
import ReactGA from "react-ga";
import { Scrollbars } from "react-custom-scrollbars";
import { makeStyles, withStyles, createMuiTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import MoreVert from "@material-ui/icons/MoreVert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import LockIcon from "@material-ui/icons/Lock";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import * as Util from "../common/Utils";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Badge from "@material-ui/core/Badge";
import Alert from "@material-ui/lab/Alert";
import { BorderBottom } from "@material-ui/icons";
import regexifyString from "regexify-string";

const OnlineAvatar = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 140px)",
    backgroundColor: theme.palette.background.paper,
  },
  cardItem: {
    boxSizing: "border-box",
    border: "1px solid black",
    borderTopLeftRadius: "255px 15px",
    borderTopRightRadius: "15px 255px",
    borderBottomRightRadius: "225px 15px",
    borderBottomLeftRadius: "15px 255px",
  },
  divider: {
    borderTopLeftRadius: "255px 15px",
    borderTopRightRadius: "15px 255px",
    borderBottomRightRadius: "225px 15px",
    borderBottomLeftRadius: "15px 255px",
  },
  noPosting: {
    margin: "100px",
    textAlign: "center",
    fontSize: "0.95rem",
  },
  noPostingWrite: {
    textAlign: "center",
    fontSize: "0.75rem",
    textDecoration: "underLine",
    cursor: "pointer",
  },
  writer: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      lineHeight: "initial",
    },
  },
  cardPublishDate: {
    color: "rgba(0,0,0,0.80)",
    fontSize: "1.1rem",
    display: "inline",
  },
  cardtop: {
    display: "flex",
    justifyContent: "space-between",
  },
  innerCardtop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  socialBig: {
    color: "rgba(0,0,0,0.49)",
    fontSize: "1.95rem",
    display: "inline",
    paddingRight: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  social: {
    color: "rgba(0,0,0,0.49)",
    fontSize: "0.95rem",
    display: "inline",
    paddingRight: "5px",
    cursor: "pointer",
  },
  moreBtn: {
    cursor: "pointer",
  },
  content: {
    color: "rgba(0,0,0,0.89)",
    fontSize: "1.25rem",
    display: "block",
    margin: "10px 0px",
  },
  inline: {
    display: "inline",
  },
  smallIcon: {
    paddingLeft: "3px",
    fontSize: "0.8rem",
  },
  alert: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "100%",
  },
  ScrollBar: {
    height: "100vh",
    // backgroundColor: "#b2c7d9",
  },
  scrollFrame: {
    backgroundImage: "url(/images/linePaper2.jpg)",
    backgroundAttachment: "local",
  },
  cardList: {
    padding: "15px 10px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withRouter(function ThankYouCard({ gubun, selUser, history }) {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [isDialog, setIsDialog] = React.useState(false);
  const [needLogin, setNeedLogin] = useState(false);
  // const [editID,setEditID] = useState('');
  const { isLogin, user } = useSelector((state) => state.user);
  let editID = useRef();
  let editLoginGubun = useRef();
  let editLoginID = useRef();

  let noPostingString1 = useRef();
  let noPostingString2 = useRef();
  let noPostingString2Link = useRef();

  const scrollbarRef = useRef();
  const beforeScrollTop = useRef(0);
  const moreDatEmpty = useRef(false);

  const dispatch = useDispatch();

  const fetcher = async (url) => {
    const resp = await axios(url);
    // console.log(resp.data);
    return resp.data;
  };

  const { data: onlineData } = useSWR(`${process.env.REACT_APP_BACK_URL}/api/onlinelist/`, fetcher, {
    refreshInterval: 30000,
  });

  // 한 컴포넌트에 useSwr 을 여러가 써서 하는건 상당히 비효율적이다.

  // 전체일기
  const { data: postData, revalidate: postDataRevalidate, setSize: postDataSetSize } = useSWRInfinite(
    (index) => `${process.env.REACT_APP_BACK_URL}/api/posts2?page=${index + 1}`
  );

  //내가 쓴 일기
  const { data: myPostData, revalidate: myPostDataRevalidate, setSize: myPostDataSetSize } = useSWRInfinite((index) => {
    if (user.loginGubun && user.id) {
      return `${process.env.REACT_APP_BACK_URL}/api/posts2/author/${user.loginGubun}/${user.id}?page=${index + 1}`;
    }
  });

  //좋아요 한 일기
  const { data: likePostData, revalidate: likePostDataRevalidate, setSize: likePostDataSetSize } = useSWRInfinite((index) => {
    if (user.loginGubun && user.id) {
      return `${process.env.REACT_APP_BACK_URL}/api/posts2/mylike/${user.loginGubun}/${user.id}?page=${index + 1}`;
    }
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuPopShow = Boolean(anchorEl);

  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const isAvatarMenuPopShow = Boolean(avatarAnchorEl);

  // 과거 채팅 로딩!
  const onScroll = useCallback(
    (values) => {
      // console.log(e);
      // console.log("values", values);
      // console.log("values.scrollTop", values.scrollTop , values.clientHeight , values.scrollTop + values.clientHeight);
      // console.log("values.scrollHeight", values.scrollHeight);

      if (values.scrollTop + values.clientHeight >= values.scrollHeight) {
        // console.log("맨아래");
        if (moreDatEmpty.current) return;
        // beforeScrollTop.current = scrollbarRef.current.view.scrollHeight;

        if (gubun === "all") {
          postDataSetSize((prevSize) => prevSize + 1).then(() => {});
        } else if (gubun === "my") {
          myPostDataSetSize((prevSize) => prevSize + 1).then(() => {});
        } else if (gubun === "like") {
          likePostDataSetSize((prevSize) => prevSize + 1).then(() => {});
        }
      }
    },
    [scrollbarRef]
  );

  const getPosts = () => {
    // console.log("gubun",gubun)
    try {
      if (gubun === "all") {
        dispatch({ type: "NAV_SELECTED", selectedIndex: 0 });
        dispatch({ type: "TOP_TITLE", title: "전체 일기보기" });
        noPostingString1.current = "아직 작성된 일기가 없습니다.";
        noPostingString2.current = "일기 쓰러가기";
        noPostingString2Link.current = "/write";

        let newPostData = [];
        // console.log(postData)
        if (postData) {
          if (Array.isArray(postData)) {
            // alert("1 1")
            // alert("postData.length : " + postData.length)
            // alert("postData[0] : " + postData[0])
            try {
              newPostData = postData.flat();
            } catch (err) {
              // alert(err);
              alert("크롬이 최신문법을 알아듣지 못하고 있어요. 크롬을 업데이트 해주세요!");
            }
            // alert("3 3")

            postData.forEach((post) => {
              // console.log(post);
              // console.log(post.length);
              if (post.length < 10) {
                // console.log("chatOldMoreDatEmpty" , "set");
                moreDatEmpty.current = true;
              }
            });
          } else {
            // alert("not array")
            newPostData.push(Util.copyObj(postData));
          }
        }

        setPosts(newPostData);
        // console.log(newPostData);
      } else if (gubun === "my") {
        dispatch({ type: "NAV_SELECTED", selectedIndex: 0 });
        dispatch({ type: "TOP_TITLE", title: "내가쓴 일기보기" });
        noPostingString1.current = "아직 작성된 일기가 없습니다.";
        noPostingString2.current = "일기 쓰러가기";
        noPostingString2Link.current = "/write";

        // console.log(user.id);
        if (user.id == "") {
          // console.log(1);
          const navUrl = "/" + window.location.pathname.split("/")[1];
          history.push("/login", { footNav: navUrl });
        } else {
          let newPostData = [];
          // console.log(myPostData)
          if (myPostData) {
            if (Array.isArray(myPostData)) {
              // console.log("array")
              newPostData = myPostData.flat();

              myPostData.forEach((post) => {
                // console.log(post);
                // console.log(post.length);
                if (post.length < 10) {
                  // console.log("chatOldMoreDatEmpty" , "set");
                  moreDatEmpty.current = true;
                }
              });
            } else {
              // console.log("not array")
              newPostData.push(Util.copyObj(myPostData));
            }
          }

          setPosts(newPostData);
        }
      } else if (gubun === "like") {
        dispatch({ type: "NAV_SELECTED", selectedIndex: 2 });
        dispatch({ type: "TOP_TITLE", title: "좋아요누른 일기" });
        noPostingString1.current = "아직 좋아요를 누른 일기가 없습니다.";
        noPostingString2.current = "일기 보러가기";
        noPostingString2Link.current = "/";

        if (user.id == "") {
          // console.log(1);
          const navUrl = "/" + window.location.pathname.split("/")[1];
          history.push("/login", { footNav: navUrl });
        } else {
          let newPostData = [];
          // console.log(likePostData)
          if (likePostData) {
            if (Array.isArray(likePostData)) {
              // console.log("array")
              newPostData = likePostData.flat();

              likePostData.forEach((post) => {
                // console.log(post);
                // console.log(post.length);
                if (post.length < 10) {
                  // console.log("chatOldMoreDatEmpty" , "set");
                  moreDatEmpty.current = true;
                }
              });
            } else {
              // console.log("not array")
              newPostData.push(Util.copyObj(myPostData));
            }
          }

          setPosts(newPostData);
        }
      } else if (gubun === "sel") {
        // 프로필에서호출.
        noPostingString1.current = "아직 작성된 일기가 없습니다.";
        noPostingString2.current = "다른 일기 보러가기";
        noPostingString2Link.current = "/";
        axios
          .get(`${process.env.REACT_APP_BACK_URL}/api/posts2/author/${selUser.loginGubun}/${selUser.id}`)
          .then((res) => {
            // console.log(res);
            setPosts(res.data);
          })
          .catch((err) => {
            console.log(err);
            setPosts([]);
          });
      }
    } catch (error) {
      console.log(error);
    }

    // console.log(posts);
  };

  const getGA = () =>{
    const pathName = window.location.pathname;
    ReactGA.initialize("UA-173628111-2");
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);    
  }

  useEffect(() => {
    getGA();
  }, []);

  useEffect(() => {
    if (postData || myPostData || likePostData) {
      getPosts();
    }
    // .then(res =>{
    //   // console.log(res);
    //   if(res && res.data){
    //     setPosts(res.data);
    //   }
    // })
  }, [postData, myPostData, likePostData]);

  const goWrite = () => {
    history.push(noPostingString2Link.current);
  };

  const onEditPop = (event) => {
    // console.log(event.target.closest(".parent").dataset.id);
    editID.current = event.target.closest(".parent").dataset.id;
    console.log(editID.current);
    setAnchorEl(event.currentTarget);
  };

  const onAvatarMenu = (event) => {
    // console.log(event.target.closest(".parent").dataset);
    editLoginGubun.current = event.target.closest(".parent").dataset.logingubun;
    editLoginID.current = event.target.closest(".parent").dataset.loginid;
    // console.log(editLoginGubun.current,editLoginID.current);
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    // editID = ''
    setAnchorEl(null);
  };

  const handleAvatarMenuClose = () => {
    // editID = ''
    setAvatarAnchorEl(null);
  };

  const handleEdit = () => {
    // console.log(editID.current)
    history.push(`/edit/${editID.current}`);
  };

  const handleDelete = () => {
    setIsDialog(true);
    handleMenuClose();
  };

  const handleProfile = () => {
    setAvatarAnchorEl(null);
    history.push(`/profile/${editLoginGubun.current}/${editLoginID.current}`);
  };

  const handleMessage = () => {
    setAvatarAnchorEl(null);
    if (isLogin) {
      history.push(`/profile/message/${editLoginGubun.current}/${editLoginID.current}`);
    } else {
      setNeedLogin(true);
      setTimeout(() => {
        setNeedLogin(false);
      }, 1500);
    }
  };

  const handleDeleteReal = () => {
    axios
      .delete(`${process.env.REACT_APP_BACK_URL}/api/posts/${editID.current}`)
      .then((res) => {
        getPosts();
        setIsDialog(false);
      })
      .catch((err) => {
        setIsDialog(false);
      });
  };

  const handleDialogClose = () => {
    setIsDialog(false);
  };

  const onDisLike = (event) => {
    let postid = event.target.closest(".parent").dataset.id;
    // console.log(event.target.dataset);
    const postIndex = posts.findIndex((item) => {
      if (item._id === postid) {
        return true;
      }
    });
    console.log(postIndex);
    axios
      .put(`${process.env.REACT_APP_BACK_URL}/api/dislikeposts/${postid}`, {
        liker: user,
      })
      .then((res) => {
        // console.log(posts)
        // console.log(res.data)
        // console.log(postIndex)
        // console.log(posts[postIndex])
        const newPosts = [...posts];
        // console.log(newPosts)
        newPosts[postIndex] = res.data[0];
        // console.log(newPosts)
        setPosts(newPosts);
        // console.log(posts)
      })
      .catch(console.error);
  };

  const onLike = (event) => {
    if (!isLogin) {
      setNeedLogin(true);
      setTimeout(() => {
        setNeedLogin(false);
      }, 1500);
      return;
    }

    let postid = event.target.closest(".parent").dataset.id;

    // console.log(event.target.parentNode.dataset.id);
    // console.log(posts);
    // let id = event.target.parentNode.dataset.id;

    // const post = posts.find(item=>{
    // if(item._id === id){
    // return true;
    // }
    // });

    // console.log(post)
    const postIndex = posts.findIndex((item) => {
      if (item._id === postid) {
        return true;
      }
    });
    // console.log(postIndex);

    // if(post.likes){
    // post.likes.push(user)
    // }else{
    // post.likes = [user]
    // }

    // const newPosts = posts;
    // newPosts[postIndex] = post;
    // console.log(newPosts);

    // if(user.loginGubun && user.id && post.likes && post.likes.find(i => i.loginGubun == user.loginGubun) && post.likes.find(i => i.id == user.id)){
    // console.log(true);
    // } else{
    // console.log(false);
    // }

    axios
      .put(`${process.env.REACT_APP_BACK_URL}/api/likeposts/${postid}`, {
        liker: user,
      })
      .then((res) => {
        // console.log("res",res)
        // console.log(posts)
        // console.log(res.data)
        // console.log(postIndex)
        // console.log(posts[postIndex])
        // const newPosts = [...posts];
        const newPosts = [...posts];
        //console.log(newPosts)
        newPosts[postIndex] = res.data[0];
        //console.log(newPosts)
        setPosts(newPosts);
        //console.log(posts)
      })
      .catch(console.error);
  };

  if (!posts) {
    return <div>로딩중</div>;
  }

  // console.log("posts" , posts);

  return (
    <>
      <List className={classes.root}>
        <Scrollbars autoHide ref={scrollbarRef} onScrollFrame={onScroll} className={classes.ScrollBar}>
          <div className={classes.scrollFrame}>
            {/* { posts.error &&
        <Typography component="div" className={classes.noPosting}>{noPostingString1}
          <Typography component="div" className={classes.noPostingWrite} onClick={goWrite}>{noPostingString2}
          </Typography>
        </Typography>
      } */}

            {!Boolean(posts.length) && (
              <Typography component="div" className={classes.noPosting}>
                {noPostingString1.current}
                <Typography component="div" className={classes.noPostingWrite} onClick={goWrite}>
                  {noPostingString2.current}
                </Typography>
              </Typography>
            )}
            {Boolean(posts.length) &&
              posts.map((post, idx, oriPosts) => {
                // console.log(post)
                return (
                  <div
                    key={idx}
                    className="cardList parent"
                    data-id={post._id}
                    data-logingubun={post.author.loginGubun}
                    data-loginid={post.author.id}
                  >
                    <div className={classes.cardList}>
                      <ListItem alignItems="flex-start" className={classes.cardItem}>
                        <ListItemAvatar>
                          {Array.isArray(onlineData) &&
                          onlineData.some(
                            (data) => data.loginGubun === post.posts_users.loginGubun && data.id === post.posts_users.loginID
                          ) ? (
                            <OnlineAvatar
                              overlap="circle"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              variant="dot"
                            >
                              <Avatar alt={post.posts_users.nickname} src={Util.httpToHttps(post.author.thumbnail_image_url)} onClick={onAvatarMenu} />
                            </OnlineAvatar>
                          ) : (
                            <Avatar alt={post.posts_users.nickname} src={Util.httpToHttps(post.author.thumbnail_image_url)} onClick={onAvatarMenu} />
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <div className={classes.cardtop}>
                                <div className={classes.writer}>
                                  <Typography component="span" className={classes.cardPublishDate}>
                                    {Util.getFormatDate(new Date(post.published_date))}
                                    {post.public != "public" && <LockIcon className={classes.smallIcon} />}
                                  </Typography>
                                  <Typography component="span">{`${post.posts_users.nickname}`}</Typography>
                                </div>

                                <div className={classes.innerCardtop}>
                                  <Typography component="div" className={classes.socialBig}>
                                    {post.likes ? post.likes.length : 0}
                                  </Typography>
                                  {user.loginGubun &&
                                  user.id &&
                                  post.likes &&
                                  post.likes.find((i) => i.loginGubun == user.loginGubun) &&
                                  post.likes.find((i) => i.id == user.id) ? (
                                    <Typography component="div" className={classes.social} data-id={post._id} onClick={onDisLike}>
                                      {" "}
                                      <FavoriteIcon />
                                    </Typography>
                                  ) : (
                                    <Typography component="div" className={classes.social} data-id={post._id} onClick={onLike}>
                                      {" "}
                                      <FavoriteBorder />
                                    </Typography>
                                  )}
                                  <Typography component="div" className={classes.social}>
                                    {" "}
                                  </Typography>{" "}
                                  {/* 여백 */}
                                  {user.loginGubun && user.loginGubun === post.author.loginGubun && user.id && user.id === post.author.id && (
                                    <Typography component="div" className={classes.moreBtn} onClick={onEditPop}>
                                      {" "}
                                      <MoreVert />
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            </>
                          }
                          secondary={
                            <>
                              <Typography component="span" variant="body2" className={classes.content} color="textPrimary">
                                {regexifyString({
                                  input: post.content,
                                  pattern: /\n|\s/g,
                                  decorator(match, index) {
                                    // console.log("reg" , match,index);
                                    if (match.match(/\n/)) {
                                      return <br />;
                                    }
                                    if (match.match(/\s/)) {
                                      return <span style={{ visibility: "hidden" }}>A</span>;
                                    }
                                  },
                                })}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </div>
                    {/* <Divider variant="inset" component="li" className={classes.divider}/> */}
                  </div>
                );
              })}
          </div>
        </Scrollbars>
      </List>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="editMenu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuPopShow}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>수정</MenuItem>
        <MenuItem onClick={handleDelete}>삭제</MenuItem>
      </Menu>
      <Menu
        anchorEl={avatarAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="avatarMenu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isAvatarMenuPopShow}
        onClose={handleAvatarMenuClose}
      >
        <MenuItem onClick={handleProfile}>프로필</MenuItem>
        <MenuItem onClick={handleMessage}>메시지보내기</MenuItem>
      </Menu>
      <Dialog
        open={isDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"데이터 삭제"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            정말 삭제하시겠습니까?
            <br />
            삭제된 데이타는 복구 불가능합니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            취소
          </Button>
          <Button onClick={handleDeleteReal} color="primary">
            삭제
          </Button>
        </DialogActions>
      </Dialog>
      {needLogin && (
        <div className={classes.alert}>
          <Alert severity="error" color="error">
            로그인후 이용가능합니다.
          </Alert>
        </div>
      )}
    </>
  );
});
