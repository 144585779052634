import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import dayjs from "dayjs";

// ** material-ui 시작 ******************************************************
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
// ** material-ui 끝 ********************************************************

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "calc(100vh - 64px)",
    backgroundColor: "#ffffff",
  },
  myList: {
    padding: "5px",
  },
  myDiv: {
    margin: "20px 7px",
    minHeight: "80px",
    // backgroundColor: "#FFC0CB",
    border: '1px solid black',
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    boxSizing: "border-box",

    borderTopLeftRadius: '255px 15px',
    borderTopRightRadius: '15px 255px',
    borderBottomRightRadius: '225px 15px',
    borderBottomLeftRadius: '15px 255px',

  },

  myDivLeft: {
    display: "flex",
  },

  myDivRight: {},

  rowText: {
    marginLeft: "15px",
    display: "flex",
    flexDirection: "column",
    "& .textBolder": {
      fontWeight: "bold",
    },
    "& .textLighter": {
      fontWeight: "lighter",
    },
  },

  ccc: {
    minHeight: "calc(100vh - 120px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default withRouter(function Chat({history}) {
  const classes = useStyles();
  const [chatRoomList, setChatRoomList] = useState([]);
  const { isLogin, user } = useSelector((state) => state.user);

  // 나와 관련있는 채팅방 목록을 보여준다!!
  const getChatRoomList = () => {
    // console.log("getChatRoomList");
    axios
      .get(`${process.env.REACT_APP_BACK_URL}/api/chatroom/`, {
        withCredentials: true,
      })
      .then((res) => {
        setChatRoomList(res.data.rdata);
      });
  };

  const goToChat = (a) => () =>{
    history.push(`/profile/message/${a.loginGubun}/${a.loginID}`);
  }

  useEffect(() => {
    getChatRoomList();
  }, []);

  // console.log(chatRoomList);

  if (!chatRoomList) {
    return <div></div>;
  }

  if (chatRoomList.length == 0) {
    return (
      <div className={classes.root}>
        <div className={classes.ccc}>
          생성된 채팅방이 없습니다
          <br />
          <br />
          일기목록의 프사 아이콘을 클릭하여 <br />
          나온 메뉴에서 채팅을방을 만들수 있습니다.
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.myList}>
        {chatRoomList.map((room) => {
          // console.log(room);
          let chatTargetUser;
          if (room.chats_users.length == 1) {
            chatTargetUser = room.chats_users[0];
          } else {
            if (room.chats_users[0].loginGubun == user.loginGubun && room.chats_users[0].loginID == user.id) {
              chatTargetUser = room.chats_users[1];
            } else if (room.chats_users[1].loginGubun == user.loginGubun && room.chats_users[1].loginID == user.id) {
              chatTargetUser = room.chats_users[0];
            }
          }

          return (
            <div className={classes.myDiv} onClick={goToChat(chatTargetUser)} key={chatTargetUser._id}>
              <div className={classes.myDivLeft}>
                <Avatar alt={chatTargetUser.nickname} src={chatTargetUser.profile_image_url} />
                <div className={classes.rowText}>
                  <Typography component="span" className={"textBolder"}>
                    {chatTargetUser.nickname}
                  </Typography>
                  <Typography component="span" className={"textLighter"}>
                    {chatTargetUser.introduce}
                  </Typography>
                </div>
              </div>
              <div className={classes.myDivRight}>
                <Typography component="span" className={""}>
                  {dayjs(room.content[0].wdatetime).format("h:mm A")}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
})
